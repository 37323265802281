import { useState } from 'react';
import { useCurrentUser } from '../../contexts/UserContext';
import 'react-datepicker/dist/react-datepicker.css';

import Table from '../../components/Table';
import Tr from '../../components/Tr';
import Th from '../../components/Th';
import { useLocation } from 'react-router-dom';
import { useMockData } from '../../contexts/MockDataContext';
import { startCase } from 'lodash';
import DocumentsFilters from './components/DocumentsFilters';
import Td from '../../components/Td';
import ActionWrapper from '../../components/ActionWrapper';
import DashIcon from '../../components/DashIcon';
import { FaGear } from 'react-icons/fa6';
import { RxOpenInNewWindow } from 'react-icons/rx';

/*
const createBOLMutation = gql`
  mutation DocumentsCreateBOLMutation($input: CreateBillOfLadingInput!) {
    createBillOfLading(input: $input) {
      billOfLading {
        id
        createdAt
        userId
        documentId
        address {
          address
        }
        balance
        carrier {
          name
        }
        account {
          buyer {
            name
          }
        }
        date
        documentNumber
        referenceNumber
      }
    }
  }
`;*/

const Documents = () => {
  //const { token } = useAuth();
  const location = useLocation();
  //const [createBOL, { loading: creating }] = useMutation(createBOLMutation);
  //const { showError, showSuccess } = useNotifications();
  const { currentUser } = useCurrentUser();
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    search: null,
    status: null,
  });
  const loading = false;
  /*const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
 

      /*acceptedFiles.forEach((file) => {
      const upload = new DirectUpload(
        file,
        '/upload',
        undefined,
        authenticationHeader,
      );
      upload.create((error, blob) => {
        if (error) {
          console.error(error);
        } else {
          let signedId = blob.signed_id;
          //let url = `/rails/active_storage/blobs/${signed_id}/${"whatever_we_want_the_filename_to_be"}`;
          createBOL({
            variables: {
              input: {
                signedId,
              },
            },
            update: (cache, { data }) => {
              console.log('UPDATE: ', data);
              // Read the current state of the query you want to update from the cache
              const existingBOLs = cache.readQuery({
                query: documentsBillOfLadings,
                variables: { first: 10 },
              });
              console.log('EXISTING BOLS: ', existingBOLs);
              // Create a new edge object for the new BOL
              const newBOLEdge = {
                __typename: 'BillOfLadingEdge',
                node: data.createBillOfLading.billOfLading, // Assuming createBOL returns the new BOL object
              };

              console.log('new bol edge: ', newBOLEdge);
              // Prepend the new BOL to the existing list
              const updatedEdges = [
                newBOLEdge,
                ...existingBOLs.billOfLadings.edges,
              ];
              console.log('about to write query: ', updatedEdges);

              // Write the updated data back to the cache
              cache.writeQuery({
                query: documentsBillOfLadings,
                data: {
                  billOfLadings: {
                    ...existingBOLs.billOfLadings,
                    edges: updatedEdges,
                  },
                },
                variables: { first: 10 },
              });
            },
          })
            .then((response) => {
              // Handle success
              console.log('BOL saved:', response.data);
              showSuccess('BOL saved');
            })
            .catch((error) => {
              // Handle error
              console.error('Error saving user:', error);
              showError('Error saving user');
            });
        }
      });
    });
    },
    [token],
  );*/

  const accountId = location.pathname.split('/').pop();
  console.log('ACCOUNT ID: ', accountId);
  const { getDocuments } = useMockData();
  /*const { data, fetchMore, loading } = useQuery(documentsBillOfLadings, {
    variables: { first: 10, accountId },
    notifyOnNetworkStatusChange: true,
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const loading = false;
  const fetchMoreBillOfLadings = () => {
    const { endCursor } = data.billOfLadings.pageInfo;
    fetchMore({
      variables: { after: endCursor },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.billOfLadings.edges;
        const pageInfo = fetchMoreResult.billOfLadings.pageInfo;

        return newEdges.length
          ? {
              billOfLadings: {
                __typename: prevResult.billOfLadings.__typename,
                edges: [...prevResult.billOfLadings.edges, ...newEdges],
                pageInfo,
              },
            }
          : prevResult;
      },
    });
  };
*/
  /*        <InfiniteScroll
          dataLength={data.billOfLadings.edges.length}
          next={fetchMoreBillOfLadings}
          hasMore={data.billOfLadings.pageInfo.hasNextPage}
          loader={<h4>Loading...</h4>}
          endMessage={<p>End of list</p>}
        >
        */

  return (
    <div>
      {currentUser?.company?.type === 'VendorCompany' && (
        <DocumentsFilters setFilter={setFilter} />
      )}
      {loading && <p>Loading...</p>}
      {!loading && (
        <Table>
          <thead>
            <Tr headerRow>
              <Th>Account</Th>
              <Th>Type</Th>
              <Th>Updated</Th>
              <Th>Status</Th>
              <Th></Th>
            </Tr>
          </thead>
          <tbody>
            {getDocuments(filter).map((document) => (
              <Tr>
                <Td>{document.account}</Td>
                <Td>{document.type}</Td>
                <Td>{document.uploaded}</Td>
                <Td>{startCase(document.status)}</Td>
                <Td>
                  <ActionWrapper>
                    <DashIcon icon={RxOpenInNewWindow} size={24} />
                    <DashIcon icon={FaGear} size={24} />
                  </ActionWrapper>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default Documents;
