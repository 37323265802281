import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useCurrentUser } from '../contexts/UserContext';
import { useAuth } from '../contexts/AuthProvider';
import { setCurrentPath, setNavigation } from '../utils/navigate';
import sizes from '../tokens/sizes';
import logo from '../assets/logo.svg';
import arrow from '../assets/arrow.svg';
import targets from '../assets/targets.svg';
import bgtarget from '../assets/bgtarget.svg';
import colors from '../tokens/colors';
import DashIcon from './DashIcon';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaGear } from 'react-icons/fa6';
import { FaCircleUser } from 'react-icons/fa6';
import { IoMdLogOut } from 'react-icons/io';
import Popup from 'reactjs-popup';
import UploadComponent from './UploadComponent';
// Styled components

import Beiersdorf_Logo from '../assets/Beiersdorf_Logo.svg';
const EverythingWrapper = styled.div`
  min-height: 100vh;
  margin: auto;
  overflow-x: hidden;
  overflow-y: clip;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  background-color: #f5f9fd;
  color: ${colors.black};
  padding-bottom: 48px;
  ::before {
    content: '';
    position: fixed;
    height: 100%;
    width: 1820px;
    z-index: -1;
    background-size: 800px;
    background-position: 100% -200px;
    background-repeat: no-repeat;
    background-image: url(${'"' + bgtarget + '"'});
    opacity: 0.05;
  }
`;

const MaxWidthWrapper = styled.div`
  width: min(${sizes.pageWidth}px, 100vw);
`;

const NavigationWrapper = styled(MaxWidthWrapper)`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: auto;
  margin-bottom: 30px;
`;
const StyledHeader = styled.header`
  height: 80px;
  width: 100%;
  background: radial-gradient(
    208.94% 208.94% at 50% 20.53%,
    #12699d 0%,
    rgba(40, 148, 213, 0.48) 100%
  );

  ::after {
    content: '';
    position: absolute;
    height: 47px;
    width: 77px;
    background: url('${targets}');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    right: 5vw;
    top: 15px;
    opacity: 0.5;
    pointer-events: none;
  }
  border-bottom: 3px solid #cae26d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
`;

const Brand = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Navigation = styled.nav`
  display: flex;
  align-items: center;
`;
const CompanyIcon = styled.img`
  width: calc(850px / 5);
  height: calc(138px / 5);
`;
const StyledNavLink = styled(Link)`
  margin: 0 1rem;
  text-decoration: none;
  color: ${colors.black};
  color: ${colors.primary};
  position: relative;
  &.active {
    ::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 0;
      width: 100%;
      height: 3px; // Set the height of your SVG
      // background-image: url('${arrow}');
      background-color: rgb(202, 226, 109);
      background-size: 100% 10px;
      background-repeat: no-repeat;
      border-radius: 2px;
    }
  }
`;

const UserGreeting = styled.a`
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  text-align: right;
  align-items: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
`;

const Chevron = styled(DashIcon)`
  margin-left: 5px;
  margin-top: 5px;
`;

const SubNav = styled.ul`
  position: absolute;
  right: 110px;
  margin: 0;
  padding: 0;
`;

const SubNavItem = styled.li`
  width: 150px;
  border-radius: 10px;
  height: 20px;
  background-color: #7ac9f8;
  padding-left: 20px;
  padding-right: 10px;
  margin-bottom: 2px;
  color: white;
  list-style-type: none;
`;

const StyledSubNavLink = styled(Link)`
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoutSubNavItem = styled(SubNavItem)`
  background-color: #d9d9d9;
  ${StyledSubNavLink} {
    color: black;
  }
`;

const Footer = styled.footer`
  height: 42px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 0;
  background: radial-gradient(
    208.94% 208.94% at 50% 20.53%,
    #12699d 0%,
    rgba(40, 148, 213, 0.48) 100%
  );
`;

const FooterList = styled.ul`
  display: flex;
  width: ${sizes.pageWidth}px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const FooterListItem = styled.li``;
const FooterListLink = styled.a`
  color: ${colors.primary};
  font-weight: 400;
  font-size: 14px;
`;

const isActive = (path) => {
  return window.location.pathname.includes(path);
};

const PageWrapper = ({ children }) => {
  const navigate = useNavigate();
  const subNavPopupRef = useRef();
  const location = useLocation();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  setNavigation(navigate);
  const { currentUser } = useCurrentUser();
  const [showSubNav, setShowSubNav] = useState(false);
  const { logout, isAuthenticated } = useAuth();

  const onClick = useCallback(() => {
    logout();
    navigate('/login');
  }, [navigate, logout]);
  const showSubNavigation = useCallback(() => {
    setShowSubNav(true);
  }, [setShowSubNav]);
  const hideSubNavigation = useCallback(() => {
    setShowSubNav(false);
  }, [setShowSubNav]);

  if (!isAuthenticated) {
    return children;
  }

  return (
    <EverythingWrapper>
      <StyledHeader>
        <NavigationWrapper>
          <Brand>
            <Link to='/' style={{ textDecoration: 'none' }}>
              <div>
                <img
                  style={{ verticalAlign: 'top' }}
                  src={logo}
                  width={130}
                  height={25}
                  alt='Dash'
                />
                <span
                  style={{
                    fontSize: 12,
                    textDecoration: 'none',
                    color: 'white',
                    verticalAlign: 'bottom',
                    fontWeight: 200,
                    marginLeft: 15,
                  }}
                >
                  Mock Data Demo
                </span>
                {/*<CompanyIcon
                  style={{ verticalAlign: 'middle', marginLeft: 15 }}
                  src={Beiersdorf_Logo}
                  alt='Beiersdorf_Logo'
                  height={50}
                  width={200}
                />*/}
              </div>
            </Link>
          </Brand>
          <Navigation>
            <StyledNavLink
              to='/overview'
              className={
                isActive('/overview') || window.location.pathname === '/'
                  ? 'active'
                  : ''
              }
            >
              Overview
            </StyledNavLink>
            <StyledNavLink
              to='/disputes'
              className={isActive('/disputes') ? 'active' : ''}
            >
              Disputes
            </StyledNavLink>
            {/*<StyledNavLink
              to='/documents'
              className={isActive('/documents') ? 'active' : ''}
            >
              Documents
            </StyledNavLink>*/}
            <StyledNavLink
              to='/escalation'
              className={isActive('/escalation') ? 'active' : ''}
            >
              PQV Escalations
            </StyledNavLink>
            <span style={{ cursor: 'pointer' }}>
              <StyledNavLink
                to='/reporting'
                className={isActive('/reporting') ? 'active' : ''}
                style={{
                  pointerEvents: 'none',
                  cursor: 'pointer',
                }}
              >
                Analytics
              </StyledNavLink>
            </span>
            {/*<StyledNavLink
              to='/settings'
              style={{ marginRight: 5, marginTop: 5 }}
            >
              <DashIcon color='#1E337B' icon={FaGear} size={24} />
            </StyledNavLink>*/}
            <StyledNavLink
              to='/settings'
              style={{ marginLeft: 0, marginRight: 0, marginTop: 3 }}
            >
              <DashIcon color='#1E337B' icon={FaCircleUser} size={24} />
            </StyledNavLink>
            {/*<Popup
              ref={subNavPopupRef}
              onOpen={showSubNavigation}
              onClose={hideSubNavigation}
              trigger={
                <UserGreeting>
                  {currentUser?.name}
                  <Chevron
                    color='#fff'
                    icon={showSubNav ? FaChevronUp : FaChevronDown}
                    size={15}
                  />
                </UserGreeting>
              }
              position='bottom left'
              contentStyle={{ padding: '0px', border: 'none' }}
              arrow={false}
            >
              <SubNav>
                <SubNavItem>
                  <StyledSubNavLink to='/settings' onClick={hideSubNavigation}>
                    Settings
                    <DashIcon color='#fff' icon={FaGear} size={18} />
                  </StyledSubNavLink>
                </SubNavItem>
                <LogoutSubNavItem>
                  <StyledSubNavLink to='/logout' onClick={onClick}>
                    Logout
                    <DashIcon color='#000' icon={IoMdLogOut} size={18} />
                  </StyledSubNavLink>
                </LogoutSubNavItem>
              </SubNav>
            </Popup>*/}
            {/*<UserGreeting>
              {currentUser ? `Welcome ${currentUser.name}` : ''}
              <a href='/logout' onClick={onClick}>
                Logout
              </a>
            </UserGreeting>(*/}
          </Navigation>
        </NavigationWrapper>
      </StyledHeader>
      <MaxWidthWrapper>{children}</MaxWidthWrapper>
      <UploadComponent />
      <Footer>
        <FooterList>
          <FooterListItem>
            <FooterListLink href='#'>Terms of Service</FooterListLink>
          </FooterListItem>
          <FooterListItem>
            <FooterListLink href='#'>Privacy Policy</FooterListLink>
          </FooterListItem>
        </FooterList>
      </Footer>
    </EverythingWrapper>
  );
};

export default PageWrapper;
