import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Legend,
  Bar,
  PieChart,
  Pie,
  Cell,
  Label,
} from 'recharts';
import { useState } from 'react';
import DashDatePicker from '../../../components/DashDatePicker';
import { getMonthsBetween } from '../../../utils/getMonthsBetween';
import { useMockData } from '../../../contexts/MockDataContext';
import { startCase } from 'lodash';
const IssueTrends = ({ startDate, endDate, title }) => {
  const { getClaims } = useMockData();

  const claims = getClaims({
    startDate,
    endDate,
  });

  function aggregateClaimsByStatus(claims) {
    const statusData = {};
    claims.forEach((claim) => {
      const status = claim.status;
      if (!statusData[status]) {
        statusData[status] = { status: startCase(status), count: 0 };
      }
      statusData[status].count += 1;
    });
    return Object.values(statusData);
  }

  const data = aggregateClaimsByStatus(claims);
  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
  }).format;

  const COLORS = ['#00C49F', '#FFBB28', '#0088FE', '#FF8042'];
  return (
    <div style={{ position: 'relative', width: 300, display: 'inline-block' }}>
      <h2>{title}</h2>
      <div style={{ position: 'relative', width: 300 }}>
        <span
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            position: 'absolute',
            left: 'calc(85px - 25px)',
            top: 60,
            width: 60,
            display: 'block',
            textAlign: 'center',
          }}
        >
          24%
        </span>
        <ResponsiveContainer width={300} height={150}>
          <PieChart>
            <Pie
              cx={80}
              cy={70}
              data={data}
              innerRadius={'70%'}
              outerRadius={'100%'}
              fill='#8884d8'
              paddingAngle={5}
              dataKey='count'
              nameKey={'status'}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend
              width={150}
              iconType='circle'
              chartWidth={300}
              verticalAlign='middle'
              align='right'
              wrapperStyle={{
                position: 'absolute',
                left: 200,
                height: 100,
                top: 'calc(50% - 50px)',
                display: 'flex',
                alignItems: 'center',
              }}
              layout={'vertical'}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default IssueTrends;
