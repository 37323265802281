import React from 'react';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';

import { gql } from '@apollo/client';

/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

// Define your styled components here
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: #f0f0f0;
  color: #333;
  padding: 8px;
  border: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #f4f4f4;
  }
`;

const usersQuery = gql`
  query UsersQuery($after: String, $first: Int = 10) {
    users(first: $first, after: $after) {
      edges {
        node {
          id
          name
          email
          company {
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const Users = () => {
  const { data, fetchMore, loading } = useQuery(usersQuery, {
    variables: { first: 10 },
    notifyOnNetworkStatusChange: true,
  });

  const fetchMoreUsers = () => {
    const { endCursor } = data.users.pageInfo;
    fetchMore({
      variables: { after: endCursor },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.users.edges;
        const pageInfo = fetchMoreResult.users.pageInfo;

        return newEdges.length
          ? {
              users: {
                __typename: prevResult.users.__typename,
                edges: [...prevResult.users.edges, ...newEdges],
                pageInfo,
              },
            }
          : prevResult;
      },
    });
  };

  return (
    <div>
      <h2>Users</h2>
      {loading && <p>Loading...</p>}
      {!loading && (
        <InfiniteScroll
          dataLength={data.users.edges.length}
          next={fetchMoreUsers}
          hasMore={data.users.pageInfo.hasNextPage}
          loader={<h4>Loading...</h4>}
          endMessage={<p>End of users list</p>}
        >
          <Table>
            <thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Company</Th>
              </Tr>
            </thead>
            <tbody>
              {data.users.edges.map(({ node }) => (
                <Tr key={node.id}>
                  <Td>{node.name}</Td>
                  <Td>{node.email}</Td>
                  <Td>{node.company.name}</Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default Users;
