import Table from '/components/Table';
import Tr from '/components/Tr';
import Th from '/components/Th';
import { useNavigate } from 'react-router-dom';
import { useMockData } from '/contexts/MockDataContext';

const Accounts = () => {
  const navigate = useNavigate();
  /*const { data, loading } = useQuery(accounts, {
    variables: { first: 10 },
    notifyOnNetworkStatusChange: true,
  });*/

  const { getAccounts } = useMockData();
  const loading = false;
  return (
    <div>
      <h1>Accounts</h1>
      {loading && <p>Loading...</p>}
      {!loading && (
        <Table>
          <thead>
            <Tr>
              <Th>Name</Th>
              <Th>Next Automatic Export</Th>
              <Th>Last Dispute Received</Th>
              <Th>Total Pending Amount</Th>
              <Th></Th>
            </Tr>
          </thead>
          <tbody>
            {getAccounts().map((account) => {
              return (
                <Tr
                  onClick={() => navigate(`/account/1234`)}
                  key={Math.random()}
                >
                  <td>{account.name} - Shortage Claim Disputes</td>
                  <td>{account.nextExport}</td>
                  <td>{account.lastClaimReceived}</td>
                  <td>{account.totalPending}</td>
                  <td>
                    <a href='/'>Edit</a>
                  </td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default Accounts;
