import styled from '@emotion/styled';
import Table from '../../components/Table';
import Tr from '../../components/Tr';
import Th from '../../components/Th';
import Td from '../../components/Td';
import { rand, useMockData } from '../../contexts/MockDataContext';
import { startCase } from 'lodash';
import DashSelect from '../../components/DashSelect';
import { FaCaretUp } from 'react-icons/fa';
import AccountOverview from './components/AccountOverview';
import { FaCaretDown } from 'react-icons/fa';
import DashIcon from '../../components/DashIcon';
import ClaimsFilters from './components/Filters';
import { useState } from 'react';
import ClaimDetails from './components/ClaimDetails';
import ActionMenu from './components/ActionMenu';
import Lavazza from '../../assets/Lavazza.png';
import Filters from './components/Filters';
import {
  formatDate,
  formatDollars,
  formatNumber,
} from '../../utils/formatting';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const AccountWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  gap: 1rem;
`;

const CompanyIcon = styled.img`
  position: absolute;
  width: calc(168px / 1.5);
  height: calc(67px / 1.5);
  left: 50%;
  margin-left: calc(-168px / (2 * 1.5));
`;

const ClaimsWrapper = styled.div`
  margin-top: 15px;
`;

const ActionCell = styled(Td)`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  margin-right: 1rem;
`;

const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
}).format;

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
}).format;

function millionFormatter(value) {
  if (value >= 1e6) {
    return `$${(value / 1e6).toFixed(1)}M`;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(value);
}

const StatusCol = styled(Td)`
  flex: 7;
`;

const CountCol = styled(Td)`
  flex: 3;
  justify-content: center;
`;

const AmountCol = styled(Td)`
  flex: 5;
  justify-content: right;
`;

interface ClaimRowProps {
  highlighted?: boolean;
}

const ClaimRow = styled(Tr)<ClaimRowProps>`
  cursor: pointer;
  ${(props) =>
    props.highlighted &&
    `
    background-color: #CEF3FE;
    :hover {
      background-color: #CEF3FE;  
    }  
  `}
`;

const BoxWrapper = styled.div`
  border: 2px solid #e5e5e5;
  box-shadow: 0px 1px 1px #e5e5e5;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  display: inline-block;
  background-color: white;
  padding-bottom: 5px;
  position: relative;
`;

function getQuarter(date) {
  const month = date.getMonth() + 1;
  if (month <= 3) return 'Q1';
  if (month <= 6) return 'Q2';
  if (month <= 9) return 'Q3';
  return 'Q4';
}

function aggregateClaimsByMonth(claims) {
  const monthData = {};
  claims.forEach((claim) => {
    const date = new Date(claim.invoiceDate);
    const year = date.getFullYear() % 100;
    const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 for 1-12
    const key = `${month.toString().padStart(2, '0')} ${year}`;
    if (!monthData[key]) {
      monthData[key] = { month: key, disputeAmount: 0 };
    }
    monthData[key].disputeAmount += claim.disputeAmount;
  });

  const sortedData = Object.values(monthData).sort((a, b) => {
    const [aMonth, aYear] = a.month.split(' ');
    const [bMonth, bYear] = b.month.split(' ');
    if (aYear !== bYear) {
      return parseInt(aYear) - parseInt(bYear);
    }
    return parseInt(aMonth) - parseInt(bMonth);
  });

  return sortedData;
}

function aggregateClaimsByQuarter(claims) {
  const quarterData = {};
  claims.forEach((claim) => {
    const date = new Date(claim.invoiceDate);
    const year = date.getFullYear() % 100;
    const quarter = getQuarter(date);
    const key = `${quarter} ${year}`;
    if (!quarterData[key]) {
      quarterData[key] = { quarter: key, disputeAmount: 0 };
    }
    quarterData[key].disputeAmount += claim.disputeAmount;
  });

  const sortedData = Object.values(quarterData).sort(
    (a: { quarter: string }, b: { quarter: string }) => {
      const [aQuarter, aYear] = a.quarter.split(' ');
      const [bQuarter, bYear] = b.quarter.split(' ');
      if (aYear !== bYear) {
        return parseInt(aYear) - parseInt(bYear);
      }
      const quarterOrder = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };
      return quarterOrder[aQuarter] - quarterOrder[bQuarter];
    },
  );

  return sortedData;
}

const Claims = () => {
  const [openIds, setOpenIds] = useState([]);
  //const navigate = useNavigate();
  /*const { data, loading } = useQuery(claims, {
    variables: { first: 10 },
    notifyOnNetworkStatusChange: true,
  });*/

  const { getClaims } = useMockData();
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    search: null,
    status: null,
  });
  const loading = false;

  const claims = getClaims(filter);
  const smartMatchClaims = claims.filter(
    (claim) => claim.status === 'SmartMatch',
  );
  const pendingEscalationClaims = claims.filter(
    (claim) => claim.status === 'PendingEscalation',
  );
  const rejectedClaims = claims.filter((claim) => claim.status === 'Rejected');
  const waitingOnAccountClaims = claims.filter(
    (claim) => claim.status === 'WaitingOnAccount',
  );
  const reimbursedClaims = claims.filter(
    (claim) => claim.status === 'Reimbursed',
  );
  const COLORS = ['#FFBB28', '#00C49F', '#FF8042'];

  const allClaimsGraphData = aggregateClaimsByQuarter(claims);
  const monthClaimsGraphData = aggregateClaimsByMonth(claims);
  const reimbursedClaimsGraphData = aggregateClaimsByQuarter(reimbursedClaims);

  const futureData = [
    {
      quarter: 'Q1 24',
      disputeAmount: 6000000 * rand(),
    },
    {
      quarter: 'Q2 24',
      disputeAmount: 6000000 * rand(),
    },
    {
      quarter: 'Q3 24',
      disputeAmount: 6000000 * rand(),
    },
    {
      quarter: 'Q4 24',
      disputeAmount: 6000000 * rand(),
    },
  ];

  for (var i = 0; i < futureData.length; i++) {
    futureData[i].recoveredAmount =
      futureData[i].disputeAmount * (0.9 + Math.random() / 10 - 0.05);
  }

  const totalReimbursed = reimbursedClaims.reduce(
    (total, claim) => total + claim.disputeAmount,
    0,
  );
  const pieData = [
    {
      name: 'Pending',
      value: claims.length - (reimbursedClaims.length + rejectedClaims.length),
    },
    {
      name: 'Recovered',
      value: reimbursedClaims.length,
    },
    {
      name: 'Rejected',
      value: rejectedClaims.length,
    },
  ];

  const pie2Data = [
    {
      name: '2nd Round',
      value: 202399,
    },
    {
      name: 'Smart Match',
      value: 637222,
    },

    {
      name: '1st Round',
      value: 472264,
    },
    {
      name: 'PQV Escalation',
      value: 618802,
      color: '#4C8EBA',
    },
  ];
  return (
    <ClaimsWrapper>
      {loading && <p>Loading...</p>}
      {!loading && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 50,
              marginTop: 47,
              marginBottom: 20,
            }}
          >
            <BoxWrapper>
              <div
                style={{ display: 'inline-block', float: 'left', margin: 20 }}
              >
                <div
                  style={{ fontSize: 24, textAlign: 'left', color: '#FFBB27' }}
                >
                  {formatDollars(
                    claims.reduce(
                      (total, claim) => total + claim.disputeAmount,
                      0,
                    ),
                  )}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Disputed
                </h3>
              </div>
              <div
                style={{ display: 'inline-block', float: 'right', margin: 20 }}
              >
                <div
                  style={{ fontSize: 24, textAlign: 'right', color: '#FFBB27' }}
                >
                  {formatNumber(claims.length)}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Claims
                </h3>
              </div>
              {/*<ResponsiveContainer
                style={{ position: 'absolute', zIndex: 999, right: 30 }}
                width='50%'
                height={100}
              >
                <PieChart>
                  <Pie
                    data={pieData}
                    fill='#8884d8'
                    paddingAngle={0} // No space between segments
                    dataKey='value'
                    nameKey={'name'}
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={numberFormatter} />
                </PieChart>
              </ResponsiveContainer>

              {/*<BarChart
                margin={{ top: 10, bottom: 93 }}
                width={500}
                height={200}
                data={monthClaimsGraphData}
              >
                <Tooltip formatter={moneyFormatter} />
                <Bar dataKey='disputeAmount' fill='#4C8EBA' />
              </BarChart>*/}
            </BoxWrapper>

            <BoxWrapper>
              <div
                style={{ display: 'inline-block', float: 'left', margin: 20 }}
              >
                <div
                  style={{ fontSize: 24, textAlign: 'left', color: '#2473A4' }}
                >
                  {formatDollars(
                    claims.reduce(
                      (total, claim) => total + claim.disputeAmount,
                      0,
                    ) -
                      reimbursedClaims.reduce(
                        (total, claim) => total + claim.disputeAmount,
                        0,
                      ),
                  )}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Pending
                </h3>
              </div>
              <div
                style={{ display: 'inline-block', float: 'right', margin: 20 }}
              >
                <div
                  style={{ fontSize: 24, textAlign: 'right', color: '#2473A4' }}
                >
                  {formatNumber(claims.length - reimbursedClaims.length)}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Claims
                </h3>
              </div>
              {/*<ResponsiveContainer
                style={{ position: 'absolute', zIndex: 999, right: 30 }}
                width='50%'
                height={100}
              >
                <PieChart>
                  <Pie
                    data={pieData}
                    fill='#8884d8'
                    paddingAngle={0} // No space between segments
                    dataKey='value'
                    nameKey={'name'}
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={numberFormatter} />
                </PieChart>
              </ResponsiveContainer>

              {/*<BarChart
                margin={{ top: 10, bottom: 93 }}
                width={500}
                height={200}
                data={monthClaimsGraphData}
              >
                <Tooltip formatter={moneyFormatter} />
                <Bar dataKey='disputeAmount' fill='#4C8EBA' />
              </BarChart>*/}
            </BoxWrapper>

            <BoxWrapper>
              <div
                style={{ display: 'inline-block', margin: 20, float: 'left' }}
              >
                <div
                  style={{
                    fontSize: 24,
                    textAlign: 'left',
                    color: '#82ca9d',
                  }}
                >
                  {formatDollars(totalReimbursed)}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Recovered
                </h3>
              </div>
              <div
                style={{ display: 'inline-block', float: 'right', margin: 20 }}
              >
                <div
                  style={{ fontSize: 24, textAlign: 'right', color: '#82ca9d' }}
                >
                  {formatNumber(reimbursedClaims.length)}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Claims
                </h3>
              </div>
              {/* <ResponsiveContainer
                style={{ position: 'absolute', zIndex: 999, left: 70 }}
                width='50%'
                height={100}
              >
                <PieChart>
                  <Pie
                    data={pie2Data}
                    fill='#8884d8'
                    paddingAngle={0} // No space between segments
                    dataKey='value'
                    nameKey={'name'}
                  >
                    {pie2Data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.color || COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={moneyFormatter} />
                </PieChart>
              </ResponsiveContainer> */}
              {/*<BarChart
                margin={{ top: 10, bottom: 93 }}
                width={500}
                height={200}
                data={monthClaimsGraphData}
              >
                <Tooltip formatter={moneyFormatter} />
                <Bar dataKey='disputeAmount' fill='#82ca9d' />
              </BarChart>*/}
            </BoxWrapper>
          </div>
          <h2
            style={{
              fontWeight: 300,
              marginBottom: 20,
              marginTop: 60,
              textAlign: 'center',
            }}
          >
            Pending Claims Overview
          </h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{
                width: '60%',
              }}
            >
              <Tr style={{ borderBottom: 0 }}>
                <StatusCol>Status</StatusCol>
                <CountCol># Claims</CountCol>
                <AmountCol>Amount</AmountCol>
              </Tr>
              <BoxWrapper
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  position: 'relative',
                  bottom: -4,
                }}
              >
                <Table>
                  <Tr>
                    <StatusCol>In Smart Match</StatusCol>
                    <CountCol>{smartMatchClaims.length}</CountCol>
                    <AmountCol>
                      {formatDollars(
                        smartMatchClaims.reduce(
                          (total, claim) => total + claim.disputeAmount,
                          0,
                        ),
                      )}
                    </AmountCol>
                  </Tr>
                  <Tr>
                    <StatusCol>In 1st Round Dispute</StatusCol>
                    <CountCol>
                      {Math.floor(rejectedClaims.length * 0.7)}
                    </CountCol>
                    <AmountCol>
                      {formatDollars(
                        rejectedClaims.reduce(
                          (total, claim) => total + claim.disputeAmount * 0.7,
                          0,
                        ),
                      )}
                    </AmountCol>
                  </Tr>
                  <Tr>
                    <StatusCol>In 2nd Round Dispute</StatusCol>
                    <CountCol>
                      {Math.floor(rejectedClaims.length * 0.3)}
                    </CountCol>
                    <AmountCol>
                      {formatDollars(
                        rejectedClaims.reduce(
                          (total, claim) => total + claim.disputeAmount * 0.3,
                          0,
                        ),
                      )}
                    </AmountCol>
                  </Tr>
                  <Tr>
                    <StatusCol>PQV Escalation</StatusCol>
                    <CountCol>{pendingEscalationClaims.length}</CountCol>
                    <AmountCol>
                      {formatDollars(
                        pendingEscalationClaims.reduce(
                          (total, claim) => total + claim.disputeAmount,
                          0,
                        ),
                      )}
                    </AmountCol>
                  </Tr>
                  <Tr style={{ borderTop: '2px solid #ddd', borderBottom: 0 }}>
                    <StatusCol style={{ fontWeight: 'bold' }}>
                      Total Pending
                    </StatusCol>
                    <CountCol style={{ fontWeight: 'bold' }}>
                      {
                        [
                          ...pendingEscalationClaims,
                          ...smartMatchClaims,
                          ...rejectedClaims,
                          ...rejectedClaims,
                        ].length
                      }
                    </CountCol>
                    <AmountCol style={{ fontWeight: 'bold' }}>
                      {formatDollars(
                        [
                          ...pendingEscalationClaims,
                          ...smartMatchClaims,
                          ...rejectedClaims,
                        ].reduce(
                          (total, claim) => total + claim.disputeAmount,
                          0,
                        ),
                      )}
                    </AmountCol>
                  </Tr>
                </Table>
              </BoxWrapper>
            </div>
            <div
              style={{
                border: '2px solid #e5e5e5',
                boxShadow: '0px 1px 1px #e5e5e5',
                backgroundColor: 'white',
                borderRadius: 5,
                width: '35%',
                height: 204,
              }}
            >
              <ResponsiveContainer height={200}>
                <PieChart>
                  <Pie
                    data={pie2Data}
                    fill='#8884d8'
                    paddingAngle={0} // No space between segments
                    dataKey='value'
                    nameKey={'name'}
                  >
                    {pie2Data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.color || COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>

                  <Legend
                    align='right'
                    layout='vertical'
                    verticalAlign='middle'
                  />
                  <Tooltip formatter={moneyFormatter} />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              gap: 50,
              marginTop: 60,
              marginBottom: 50,
            }}
          >
            <BoxWrapper style={{ textAlign: 'center', width: '50%' }}>
              <h3 style={{ fontWeight: 300 }}>Previous Year</h3>
              <ResponsiveContainer width={400} height={200}>
                <AreaChart
                  margin={{ left: 50, right: 5, top: 15, bottom: 20 }}
                  data={allClaimsGraphData.slice(-5)}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='quarter' />
                  <YAxis tickFormatter={millionFormatter} />
                  <Tooltip formatter={moneyFormatter} />
                  <Area
                    type='monotone'
                    dataKey='disputeAmount'
                    stroke='#2473A4'
                    fill='#0000'
                    name='Issued'
                  />
                  <Area
                    type='monotone'
                    dataKey='disputeAmount'
                    data={reimbursedClaimsGraphData.slice(-5)}
                    stroke='#82ca9d'
                    fill='#82ca9d'
                    name='Recovered'
                  />
                  <Legend />
                </AreaChart>
              </ResponsiveContainer>
            </BoxWrapper>

            <BoxWrapper style={{ textAlign: 'center', width: '50%' }}>
              <h3 style={{ fontWeight: 300 }}>Future Projection</h3>
              <ResponsiveContainer width={400} height={200}>
                <AreaChart
                  margin={{ left: 50, right: 5, top: 15, bottom: 20 }}
                  data={futureData}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='quarter' />
                  <YAxis tickFormatter={millionFormatter} />
                  <Tooltip formatter={moneyFormatter} />
                  <Area
                    type='monotone'
                    dataKey='disputeAmount'
                    stroke='#2473A4'
                    fill='#0000'
                    name='Issued'
                  />
                  <Area
                    type='monotone'
                    dataKey='recoveredAmount'
                    stroke='#82ca9d'
                    fill='#82ca9d'
                    name='Recovered'
                  />
                  <Legend />
                </AreaChart>
              </ResponsiveContainer>
            </BoxWrapper>
          </div>

          {/*<LineChart
            width={730}
            height={250}
            data={claims}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type='monotone' dataKey='dueDate' stroke='#8884d8' />
            <Line type='monotone' dataKey='uv' stroke='#82ca9d' />
          </LineChart>

          <Table>
            <div>
              <Tr headerRow>
                <Th>Invoice #</Th>
                <Th>Purchase Order</Th>
                <Th>Invoice Date</Th>
                <Th>Invoice Amount</Th>
                <Th>Status</Th>
                <Th></Th>
              </Tr>
            </div>
            <div></div>
          </Table>*/}
        </>
      )}
    </ClaimsWrapper>
  );
};

export default Claims;
