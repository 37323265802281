import styled from '@emotion/styled';

const ActionWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
`;

export default ActionWrapper;
