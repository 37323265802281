import styled from '@emotion/styled';
import colors from '../tokens/colors';
import { forwardRef } from 'react';
import { IconBaseProps } from 'react-icons';

const StyledIcon = styled.div`
  color: ${colors.iconColor};
  cursor: pointer;
  position: relative;
`;

type DashIconProps = {
  icon: React.ElementType;
  // Include other props here
} & IconBaseProps;

// Adjusting to wrap the icon in a div that receives the ref
const DashIcon = forwardRef<HTMLDivElement, DashIconProps>(
  ({ icon: Icon, ...props }, ref) => {
    // Wrap Icon in a div and apply ref to the div
    return (
      <StyledIcon ref={ref}>
        <Icon {...props} />
      </StyledIcon>
    );
  },
);

export default DashIcon;
