import React from 'react';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaTrash, FaMask, FaEdit } from 'react-icons/fa';
import { gql, useMutation } from '@apollo/client';
import { partial } from 'lodash';
/** @jsxImportSource @emotion/react */
import { useNotifications } from '../../../../contexts/NotificationsContext';
import { useAuth } from '../../../../contexts/AuthProvider';
import UserForm from './components/UserForm';
import { useModal } from '../../../../contexts/ModalContext';
import Table from '../../../../components/Table';
import Tr from '../../../../components/Tr';
import Th from '../../../../components/Th';
import Td from '../../../../components/Td';
import ActionWrapper from '../../../../components/ActionWrapper';
import DashIcon from '../../../../components/DashIcon';

const usersQuery = gql`
  query UsersQuery($after: String, $first: Int = 10) {
    users(first: $first, after: $after) {
      edges {
        node {
          id
          name
          email
          admin
          company {
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const deleteUserMutation = gql`
  mutation deleteUserMutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
      }
      errors
    }
  }
`;

const maskAsUserMutation = gql`
  mutation maskAsUserMutation($id: ID!) {
    maskAsUser(input: { id: $id }) {
      token
    }
  }
`;

const Users = () => {
  const { showError } = useNotifications();
  const { setMaskedToken } = useAuth();
  const { showModal } = useModal();
  const { data, fetchMore, loading } = useQuery(usersQuery, {
    variables: { first: 10 },
    notifyOnNetworkStatusChange: true,
  });

  const [deleteUser] = useMutation(deleteUserMutation, {
    onError: (error) => {
      // Optionally handle the error more specifically
      console.log('error: ', error);
      showError('Error deleting user');
    },
  });

  const [maskAsUser] = useMutation(maskAsUserMutation, {
    onError: (error) => {
      // Optionally handle the error more specifically
      showError('Error masking as User');
    },
  });

  const deleteUserCallback = (id) => {
    deleteUser({
      variables: { input: { id } },
      update: (cache) => {
        // Read the current data from the cache for the query you want to update
        const existingUsers = cache.readQuery({ query: usersQuery });
        if (existingUsers && existingUsers.users) {
          // Filter out the deleted user
          const updatedUsers = existingUsers.users.edges.filter(
            (user) => user.id !== id,
          );
          // Write the updated list back to the cache
          cache.writeQuery({
            query: usersQuery,
            data: { users: updatedUsers },
          });
        }
      },
    });
  };

  const maskAsUserCallback = async (id) => {
    const { data } = await maskAsUser({
      variables: { id },
    });

    const token = data.maskAsUser.token;
    setMaskedToken(token);
    window.open(window.location.origin, '_blank');
  };

  const fetchMoreUsers = () => {
    const { endCursor } = data.users.pageInfo;
    fetchMore({
      variables: { after: endCursor },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.users.edges;
        const pageInfo = fetchMoreResult.users.pageInfo;

        return newEdges.length
          ? {
              users: {
                __typename: prevResult.users.__typename,
                edges: [...prevResult.users.edges, ...newEdges],
                pageInfo,
              },
            }
          : prevResult;
      },
    });
  };

  const addUser = () => {
    showModal(<UserForm />);
  };

  const editUser = (id) => {
    showModal(<UserForm userId={id} />);
  };

  return (
    <div>
      <h2>Users</h2>
      <button onClick={addUser}>Create User</button>
      {loading && <p>Loading...</p>}
      {!loading && (
        <InfiniteScroll
          dataLength={data.users.edges.length}
          next={fetchMoreUsers}
          hasMore={data.users.pageInfo.hasNextPage}
          loader={<h4>Loading...</h4>}
          endMessage={<p>End of users list</p>}
        >
          <Table>
            <thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Company</Th>
                <Th>Admin</Th>
                <Th></Th>
              </Tr>
            </thead>
            <tbody>
              {data.users.edges.map(({ node }) => (
                <Tr key={node.id}>
                  <Td>{node.name}</Td>
                  <Td>{node.email}</Td>
                  <Td>{node.company.name}</Td>
                  <Td>{node.admin ? '✅' : null}</Td>
                  <Td>
                    <ActionWrapper>
                      <DashIcon
                        onClick={partial(editUser, node.id)}
                        icon={FaEdit}
                        size={24}
                      />
                      <DashIcon
                        onClick={partial(deleteUserCallback, node.id)}
                        icon={FaTrash}
                        size={24}
                      />
                      <DashIcon
                        onClick={partial(maskAsUserCallback, node.id)}
                        icon={FaMask}
                        size={24}
                      />
                    </ActionWrapper>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default Users;
