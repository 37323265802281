import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Legend,
  Bar,
  PieChart,
  Pie,
  Cell,
  Label,
} from 'recharts';
import { useState } from 'react';
import DashDatePicker from '../../../components/DashDatePicker';
import { getMonthsBetween } from '../../../utils/getMonthsBetween';
import { useMockData } from '../../../contexts/MockDataContext';

const Projections = ({}) => {
  const data = [
    {
      status: 'Reimbursed',
      count: 12344,
    },
    {
      status: 'Rejected',
      count: 1234,
    },
  ];
  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
  }).format;

  const COLORS = ['#00C49F', '#FF8042'];
  return (
    <div>
      <h2>Upcoming Quarter Projections</h2>
      <ResponsiveContainer width={300} height={300}>
        <PieChart>
          <Pie
            data={data}
            fill='#8884d8'
            paddingAngle={0}
            dataKey='count'
            nameKey={'status'}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Projections;
