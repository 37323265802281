import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useNotifications } from './NotificationsContext';
import { onError } from '@apollo/client/link/error';
import navigate from '../utils/navigate';
import { useAuth } from './AuthProvider';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

export const InternalApolloProvider = ({ children }) => {
  const { showWarning } = useNotifications();
  const { logout, token } = useAuth();

  const cable = createConsumer('/cable?token=' + token);
  const actionCableLink = new ActionCableLink({ cable });

  const httpLink = createHttpLink({
    uri: '/graphql',
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    console.log('ON ERROR: ', graphQLErrors, networkError);
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === 'Unauthorized') {
          logout();
          showWarning('You no longer have authorization.');
          navigate('/login');
        }
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    actionCableLink,
    httpLink,
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authLink, errorLink, splitLink]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
