
import styled from '@emotion/styled';
import colors from '../tokens/colors';

const Th = styled.div`
  background-color: ${colors.tableHeader};
  padding: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #333;
  white-space: nowrap;
  border: none;
  justify-content: left;
  flex: 1 1 0%;
  display: flex;
  &:first-of-type {
    border-radius: 20px 0 0 20px;
  }
  &:last-of-type {
    border-radius: 0 20px 20px 0;
  }
  text-align: left;
`;

export default Th;