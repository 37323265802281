let navigate = null
let currentPath = '';

export const setCurrentPath = (path) => {
  currentPath = path;
};

export const getCurrentPath = () => currentPath;

export const setNavigation = (nav) => {
  navigate = nav
}

const navigateFnc = (prop1, prop2) => {
  navigate?.(prop1, prop2)
}

export default navigateFnc

