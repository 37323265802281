import { createContext, useContext, useMemo, useState } from 'react';
import { isAfter, addDays } from 'date-fns';

let randomSeed = 10;
function seededRandom(seed) {
  // Constants for the LCG
  const a = 1664525;
  const c = 1013904223;
  const m = Math.pow(2, 32);

  let state = seed;

  return function () {
    state = (a * state + c) % m;
    return state / m;
  };
}

export const rand = seededRandom(11);

const monthData = {
  '2024-03-01': 1252001,
  '2024-02-01': 1234008,
  '2024-01-01': 1213556,
  '2023-12-01': 1203938,
  '2023-11-01': 1086350,
  '2023-10-01': 1335054,
  '2023-09-01': 1700698,
  '2023-08-01': 1148807,
  '2023-07-01': 976450,
  '2023-06-01': 1144107,
  '2023-05-01': 959534,
  '2023-04-01': 1052170,
  '2023-03-01': 2383767,
  '2023-02-01': 1943958,
  '2023-01-01': 1322240,
};

const accounts = [
  {
    name: 'Amazon Vendor Central',
    nextExport: '05/05/2024',
    lastClaimReceived: '01/01/2024',
    totalPending: '$132,123',
  },
];

const carriers = [
  {
    name: 'Carrier X',
    connected: true,
  },
  {
    name: 'Carrier Y',
    connected: true,
  },
  {
    name: 'Carrier Z',
    connected: true,
  },
];

const issues = [
  'Carrier Issue',
  'Packaging Error',
  'Missing Labels',
  'Case Quantity',
];

const claimStatuses = [
  'PendingEscalation',
  'Reimbursed',
  'Rejected',
  'MissingDocuments',
  'SmartMatch',
  'Canceled',
  'WaitingOnAccount',
];

let claims = [];
let maxClaimValue = 4000;

const entries = Object.entries(monthData);
for (let [monthDate, monthTotal] of entries) {
  while (monthTotal > 0) {
    let claimValue = Math.floor(rand() * 4000);
    if (monthTotal < maxClaimValue) {
      claimValue = monthTotal;
    }
    monthTotal -= claimValue;
    const randType = rand();
    console.log('RAND TYPE: ', randType);
    let claimType = 'Reimbursed';
    if (randType <= 0.1) {
      claimType = 'Rejected';
      if (randType <= 1) {
        const otherTypes = ['PendingEscalation', 'Rejected', 'SmartMatch'];
        claimType = otherTypes[Math.floor(rand() * 3)];
      }
    }
    const date = addDays(new Date(monthDate), Math.floor(rand() * 29));
    claims.push({
      id: Math.floor(rand() * 10000),
      status: claimType,
      claimNumber: Math.floor(rand() * 10000),
      invoiceNumber: Math.floor(rand() * 10000),
      dueDate: date,
      po: Math.floor(rand() * 10000),
      invoiceQty: Math.floor(rand() * 10),
      invoiceDate: date,
      disputeQty: Math.floor(rand() * 10),
      invoiceAmount: Math.floor(rand() * 1000),
      disputeAmount: claimValue,
      bol: claimType !== 'MissingDocuments' ? '/pdfs/1.pdf' : null,
      invoice: claimType !== 'MissingDocuments' ? '/pdfs/2.pdf' : null,
      issue: issues[Math.floor(rand() * 4)],
    });
  }
}

console.log('CLAIMS:', claims);
/*
const claims = [
  'MissingDocuments',
  'MissingDocuments',
  'MissingDocuments',
  'MissingDocuments',
  'MissingDocuments',
  'MissingDocuments',
  ...claimStatuses,
].map((status) => {
  return {
    id: Math.floor(Math.random() * 10000),
    status,
    claimNumber: Math.floor(Math.random() * 10000),
    invoiceNumber: Math.floor(Math.random() * 10000),
    dueDate: format(
      addDays(new Date(), Math.floor(Math.random() * 365) + 1),
      'MM/dd/yyyy',
    ),
    po: Math.floor(Math.random() * 10000),
    invoiceQty: Math.floor(Math.random() * 10),
    invoiceDate: format(
      addDays(new Date(), Math.floor(Math.random() * 365) + 1),
      'MM/dd/yyyy',
    ),
    disputeQty: Math.floor(Math.random() * 10),
    invoiceAmount: Math.floor(Math.random() * 1000),
    disputeAmount: Math.floor(Math.random() * 1000),
    bol: status !== 'MissingDocuments' ? '/pdfs/1.pdf' : null,
    invoice: status !== 'MissingDocuments' ? '/pdfs/2.pdf' : null,
  };
});*/

const docStatuses = ['Associated', 'PendingClaim'];
const docTypes = ['BillOfLading', 'Invoice'];

const documents = docStatuses.flatMap((status) => {
  if (status === 'Processing') {
    return {
      uploaded: '05/06/2024',
      status,
    };
  }
  return docTypes.map((type) => {
    return {
      status,
      type,
      uploaded: '05/05/2024',
      account: 'Amazon',
    };
  });
});

const MockDataContext = createContext({
  getAccounts: (content: React.ReactNode) => [],
  getClaims: (filters?: any) => [],
  getDocuments: (filters?: any) => [],
  getDocumentStatuses: () => [],
  getClaimStatuses: () => [],
  fillInFirstClaim: () => {},
});

export const MockDataProvider = ({ children }) => {
  const [, setTick] = useState(0);
  const value = useMemo(
    () => ({
      getAccounts: () => accounts,
      fillInFirstClaim: () => {
        for (let claim of claims) {
          if (claim.status === 'MissingDocuments') {
            claim.status = 'WaitingOnAccount';
            claim.bol = '/pdfs/1.pdf';
            claim.invoice = '/pdfs/2.pdf';
            setTick((prev) => prev + 1);
            return;
          }
        }
      },
      getClaims: (filters) => {
        return claims.filter((claim) => {
          let hit = true;
          if (!filters) return hit;
          if (filters.statuses?.length > 0) {
            hit = hit && filters.statuses.includes(claim.status);
          }
          if (filters.startDate) {
            hit = hit && isAfter(claim.dueDate, filters.startDate);
          }
          if (filters.endDate) {
            hit = hit && !isAfter(claim.dueDate, filters.endDate);
          }
          if (filters.search) {
            hit = hit && JSON.stringify(claim).includes(filters.search);
          }

          return hit;
        });

        //return claims;
      },
      getDocuments: (filters) => {
        return documents.filter((document) => {
          let hit = true;
          if (!filters) return hit;
          if (filters.status) {
            hit = hit && document.status === filters.status;
          }
          if (filters.startDate) {
            hit = hit && isAfter(document.uploaded, filters.startDate);
          }
          if (filters.endDate) {
            hit = hit && !isAfter(document.uploaded, filters.endDate);
          }
          if (filters.search) {
            hit = hit && JSON.stringify(document).includes(filters.search);
          }

          return hit;
        });

        //return claims;
      },
      getDocumentStatuses: () => docStatuses,
      getClaimStatuses: () => claimStatuses,
    }),
    [],
  );
  return (
    <MockDataContext.Provider value={value}>
      {children}
    </MockDataContext.Provider>
  );
};

export const useMockData = () => useContext(MockDataContext);
