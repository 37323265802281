import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from '../components/modal';
//import { pdfjs } from 'react-pdf';
import { LuPlusCircle } from 'react-icons/lu';
import { TbExclamationCircle } from 'react-icons/tb';
import styled from '@emotion/styled';
import DashIcon from '../components/DashIcon';
import { BsCloudCheckFill } from 'react-icons/bs';
// import { DirectUpload } from '@rails/activestorage';
// import { useAuth } from './AuthProvider';
import { format } from 'date-fns';
import { some } from 'lodash';
import { useMockData } from './MockDataContext';
import Popup from 'reactjs-popup';
const UploadManagerContext = createContext({
  showUploadManager: () => {},
  closeUploadManager: () => {},
  progress: 100,
});

/*async function extractTextFromAllPages(pdfPath) {
  const loadingTask = pdfjs.getDocument(pdfPath);
  const pdf = await loadingTask.promise;
  const numPages = pdf.numPages;
  let fullText = '';

  for (let pageNum = 1; pageNum <= numPages; pageNum++) {
    const page = await pdf.getPage(pageNum);
    const textContent = await page.getTextContent();
    const pageText = textContent.items.map((item) => item.str).join(' ');
    fullText += pageText + '\n'; // Append page text and a newline character
  }

  return fullText;
}*/

const DropPad = styled.div`
  margin-top: 20px;
  background-color: #eee;
  border-radius: 15px;
  border: 2px dashed #777;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 135px;
`;

const DropText = styled.span`
  font-size: 16px;
  line-height: 250%;
  color: #777777;
`;

const UploadTitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  text-align: left;
`;

const Th = styled.th`
  font-size: 12px;
  color: #777777;
  text-align: center;
`;

const Td = styled.td`
  font-size: 14px;
  text-align: center;
`;

const EmptyText = styled.span`
  font-size: 12px;
  display: inline-block;
  margin: 10px;
`;

function formatFileSize(bytes) {
  if (bytes < 1024) return bytes + ' B';
  let units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = 0;
  while (bytes >= 1024 && i < units.length - 1) {
    bytes /= 1024;
    i++;
  }
  return bytes.toFixed(1) + ' ' + units[i];
}

/*class Uploader {
  constructor(file, url, token) {
    const authenticationHeader = {
      Authorization: `Bearer ${token}`,
    };

    this.file = file;
    this.url = url;
    this.upload = new DirectUpload(file, url, this, authenticationHeader);
  }

  upload(callback) {
    console.log('CALLING CREATE: ', this.upload);
    this.upload.create(callback);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) =>
      this.directUploadDidProgress(event),
    );
  }

  directUploadDidProgress(event) {
    console.log('PROGRESS: ', event.loaded, event.total);
    // Use event.loaded and event.total to update the progress bar
  }
}*/

export const UploadManagerProvider = ({ children }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { fillInFirstClaim } = useMockData();
  //const { token } = useAuth();
  const [files, setFiles] = useState([
    {
      time: '9:30 am',
      name: '1.pdf',
      size: '1.2 MB',
      status: 'Rejected',
      reason: 'File type must be application/pdf,.pdf',
      uploadPercentage: 0,
    },
  ]);

  const uploadingFiles = files.filter((file) => file.status === 'Uploading');
  const totalCompleted = uploadingFiles.reduce((acc, file) => {
    return acc + file.uploadPercentage;
  }, 0);
  console.log('UPLOADING FILES: ');
  const progress =
    uploadingFiles.length > 0
      ? 100 * (totalCompleted / (uploadingFiles.length * 100))
      : 100;

  useEffect(() => {
    console.log('CHECKING: ', files, some(files, { status: 'Uploading' }));
    if (some(files, { status: 'Uploading' })) {
      setTimeout(() => {
        setFiles((prevFiles) => {
          return prevFiles.map((file) => {
            console.log('CHECKING FILE: ', file, file.status);
            if (file.status === 'Uploading') {
              const randomAdd = Math.floor(Math.random() * 10) + 1;
              file.uploadPercentage = Math.min(
                file.uploadPercentage + randomAdd,
                100,
              );
              if (file.uploadPercentage === 100) {
                file.status = 'Complete';
                fillInFirstClaim();
              }
            }
            return file;
          });
        });
      }, 500);
    }
  }, [files, fillInFirstClaim]);

  const { open, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles, e) => {
      setIsDragging(false);
      setIsActive(true);
      setFiles((prevFiles) => {
        const accepted = acceptedFiles.map((file) => {
          return {
            time: format(Date.now(), 'h:mm a'),
            name: file.name,
            size: formatFileSize(file.size),
            status: 'Uploading',
            uploadPercentage: 0,
          };
        });

        const rejected = rejectedFiles.map((rejection) => ({
          time: format(Date.now(), 'h:mm a'),
          name: rejection.file.name,
          size: formatFileSize(rejection.file.size),
          status: 'Rejected',
          reason: rejection.errors[0].message,
          uploadPercentage: 0,
        }));
        return [...rejected, ...accepted, ...prevFiles];
      });

      /*var fileReader = new FileReader();
      fileReader.onload = function (event) {
        var typedarray = new Uint8Array(event.target.result as ArrayBuffer);
        extractTextFromAllPages(typedarray).then((text) => {
          parseBOL(text);
        });
      };
      fileReader.readAsArrayBuffer(file);*/

      /*      const upload = new Uploader(file, '/upload', token);
      console.log('upload', upload);

      upload.upload((error, blob) => {
        if (error) {
          console.error(error);
        } else {
          let signedId = blob.signed_id;
          console.log('SIGNED ID: ', signedId);
        }
      });*/

      /*const authenticationHeader = {
        Authorization: `Bearer ${token}`,
      };
      const upload = new DirectUpload(
        file,
        '/upload',
        {
          directUploadWillStoreFileWithXHR: (request) => {
            console.log('request', request);
            request.upload.addEventListener('progress', (event) =>
              console.log(
                'UPLOAD PROGRESS: ',
                event,
                event.loaded,
                event.total,
              ),
            );
          },
        },
        authenticationHeader,
      );

      upload.create((error, blob) => {
        if (error) {
          console.error(error);
        } else {
          let signedId = blob.signed_id;
          console.log('SIGNED ID: ', signedId);
        }
      });*/
    },
    noClick: true,
    multiple: false,
    accept: {
      'application/pdf': ['.pdf'],
    },
    onFileDialogCancel: () => {},
    onDragEnter: () => {
      setIsDragging(true);
    },
    onDragLeave: () => {
      setIsDragging(false);
    },
  });

  useEffect(() => {
    if (openCheck) {
      setOpenCheck(false);
      if (progress === 100) {
        open();
      }
    }
  }, [open, openCheck, progress]);
  const showUploadManager = useCallback(() => {
    setIsActive(true);
    setOpenCheck(true);
  }, [setIsActive]);

  const closeUploadManager = useCallback(() => {
    setIsActive(false);
  }, [setIsActive]);

  return (
    <UploadManagerContext.Provider
      value={{ showUploadManager, closeUploadManager, progress }}
    >
      <div {...getRootProps()}>
        {children}

        {(isDragging || isActive) && (
          <Modal onOverlayClick={closeUploadManager}>
            <UploadTitle>Upload History</UploadTitle>
            <Table>
              <thead>
                <tr>
                  <Th>Time</Th>
                  <Th>File Name</Th>
                  <Th>Size</Th>
                  <Th>Progress</Th>
                  <Th>Status</Th>
                </tr>
              </thead>
              <tbody>
                {files.map((file) => (
                  <tr key={file.name}>
                    <Td>{file.time}</Td>
                    <Td>{file.name}</Td>
                    <Td>{file.size}</Td>
                    <Td>{file.uploadPercentage}</Td>
                    <Td>
                      {file.status === 'Complete' ? (
                        <DashIcon icon={BsCloudCheckFill} size={24} />
                      ) : null}
                      {file.status === 'Rejected' ? (
                        <Popup
                          on={['hover', 'click']}
                          trigger={
                            <DashIcon
                              color='#DA3C3C'
                              icon={TbExclamationCircle}
                              size={24}
                            />
                          }
                        >
                          <span>{file.reason}</span>
                        </Popup>
                      ) : null}
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {files.length === 0 && <EmptyText>No Uploads</EmptyText>}
            <DropPad>
              <DropText>
                Drag and drop files or click ot select a file for upload
              </DropText>
              <DashIcon icon={LuPlusCircle} size={24} />
              <input {...getInputProps()} />
            </DropPad>
          </Modal>
        )}
      </div>
    </UploadManagerContext.Provider>
  );
};

export const useUploadManager = () => useContext(UploadManagerContext);
