import styled from '@emotion/styled';
const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.5rem;
`;
export default FilterWrapper;
