import styled from '@emotion/styled';
import Table from '../../components/Table';
import Tr from '../../components/Tr';
import Th from '../../components/Th';
import Td from '../../components/Td';
import { useMockData } from '../../contexts/MockDataContext';
import { startCase } from 'lodash';
import DashSelect from '../../components/DashSelect';
import { FaCaretUp } from 'react-icons/fa';
import AccountOverview from './components/AccountOverview';
import { FaCaretDown } from 'react-icons/fa';
import DashIcon from '../../components/DashIcon';
import ClaimsFilters from './components/ClaimsFilters';
import { useState } from 'react';
import ClaimDetails from './components/ClaimDetails';
import ActionMenu from './components/ActionMenu';
import Beiersdorf_Logo from '../../assets/Beiersdorf_Logo.svg';
import AmazonVendorCentralLogo from '../../assets/amazon_vendor_central.png';
import {
  formatDate,
  formatDollars,
  formatNumber,
} from '../../utils/formatting';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const AccountWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  gap: 1rem;
  margin-bottom: 20px;
`;
const BoxWrapper = styled.div`
  border: 2px solid #e5e5e5;
  box-shadow: 0px 1px 1px #e5e5e5;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  display: inline-block;
  background-color: white;
  padding-bottom: 5px;
`;

const CompanyIcon = styled.img`
  width: calc(850px / 4);
  height: calc(138px / 4);
`;

const ClaimsWrapper = styled.div`
  margin-top: 15px;
`;

const ActionCell = styled(Td)`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;

interface ClaimRowProps {
  highlighted?: boolean;
}

const ClaimRow = styled(Tr)<ClaimRowProps>`
  cursor: pointer;
  ${(props) =>
    props.highlighted &&
    `
    background-color: #f4f4f4;
    :hover {
      background-color: #f4f4f4;  
    }  
  `}
`;

interface StatusWrapperProps {
  paid?: boolean;
  rejected?: boolean;
  pending?: boolean;
}

const StatusWrapper = styled.span<StatusWrapperProps>`
  display: block;
  width: 120px;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  ${(props) => props.paid && `background-color: #EBF9EB; color: #5ACB57;`}
  ${(props) => props.rejected && `background-color: #FCD8CB; color: #F56B45;`}
  ${(props) => props.pending && `background-color: #FEF8E7; color: #F5C957;`}
`;
const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
}).format;

const InfoBox = ({
  title,
  amount,
  claimsRejected,
  claimsReimbursed,
  claimsPending,
}) => {
  const data = [
    {
      name: 'Claims Pending⠀⠀',
      value: claimsPending,
    },
    { name: 'Claims Reimbursed', value: claimsReimbursed },
    { name: 'Claims Rejected⠀⠀', value: claimsRejected },
  ];

  const COLORS = ['#FFBB28', '#00C49F', '#FF8042'];
  console.log('DATA: ', data);
  return (
    <BoxWrapper
      style={{
        position: 'relative',
        width: 'calc(50% - 10px)',
        height: 190,
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20,
        borderRadius: 5,
      }}
    >
      <div>
        <h3 style={{ padding: 0, margin: 0 }}>{title}</h3>
        <span
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: title === 'Shortage Disputes Recovered' ? '#00C49F' : null,
            marginTop: 20,
            display: 'block',
          }}
        >
          {amount}
        </span>
        <h3 style={{ padding: 0, margin: 0, marginTop: 20 }}>{title}</h3>
        <span
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: title === 'Shortage Disputes Recovered' ? '#00C49F' : null,
            marginTop: 20,
            display: 'block',
          }}
        >
          {amount}
        </span>
      </div>
      <ResponsiveContainer
        style={{ position: 'absolute', left: 250 }}
        width='50%'
        height={250}
      >
        <PieChart>
          <Pie
            data={data}
            fill='#8884d8'
            paddingAngle={0} // No space between segments
            dataKey='value'
            nameKey={'name'}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={numberFormatter} />
          <Legend
            layout='vertical'
            wrapperStyle={{
              position: 'absolute',
              textAlign: 'left',
              top: 110,
              left: 0,
              zIndex: -1,
            }}
            style={{
              textAlign: 'left',
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </BoxWrapper>
  );
};

const Claims = () => {
  const [openIds, setOpenIds] = useState([]);
  //const navigate = useNavigate();
  /*const { data, loading } = useQuery(claims, {
    variables: { first: 10 },
    notifyOnNetworkStatusChange: true,
  });*/

  const mapStatus = (status) => {
    switch (status) {
      case 'Reimbursed':
        return <StatusWrapper paid>{startCase(status)}</StatusWrapper>;
      case 'Rejected':
        return <StatusWrapper rejected>{startCase(status)}</StatusWrapper>;
      case 'PendingEscalation':
        return <StatusWrapper pending>{'Pending'}</StatusWrapper>;
      default:
        return <StatusWrapper pending>{startCase(status)}</StatusWrapper>;
    }
  };

  const { getClaims } = useMockData();
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    search: null,
    status: null,
  });
  const loading = false;
  const claims = getClaims(filter);
  const reimbursedClaims = claims.filter(
    (claim) => claim.status === 'Reimbursed',
  );
  const claimsRejected = claims.filter((claim) => claim.status === 'Rejected');
  const pendingClaims = claims.filter(
    (claim) => claim.status !== 'Rejected' && claim.status !== 'Reimbursed',
  );
  const totalReimbursed = reimbursedClaims.reduce(
    (total, claim) => total + claim.disputeAmount,
    0,
  );
  return (
    <ClaimsWrapper>
      {loading && <p>Loading...</p>}
      {!loading && (
        <>
          {/*<div
            style={{
              position: 'absolute',
              textAlign: 'center',
              right: 0,
              width: 200,
              height: 300,
              margin: 10,
            }}
          >
            <h2 style={{ margin: 0 }}>Connections</h2>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <img height={50} src={AmazonVendorCentralLogo} />
              <a href=''>Connect</a>
            </div>
            {Array(42)
              .fill(0)
              .map((_, i) => (
                <div
                  key={i} // Adding a key is important when rendering lists in React
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>Carrier {i + 1}</span>
                  <span style={{ color: '#00C49F' }}>Connected</span>
                </div>
              ))}
          </div>*/}
          {/*<AccountWrapper>
            <DashIcon icon={FaPen} onClick={() => {}} />
            <CompanyIcon
              src={Beiersdorf_Logo}
              alt='Beiersdorf_Logo'
              height={208}
              width={850}
            />
          </AccountWrapper>*/}
          <ClaimsFilters setFilter={setFilter} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 50,
              marginTop: 47,
              marginBottom: 50,
            }}
          >
            <BoxWrapper>
              <div
                style={{ display: 'inline-block', float: 'left', margin: 20 }}
              >
                <div
                  style={{ fontSize: 24, textAlign: 'left', color: '#FFBB27' }}
                >
                  {formatDollars(
                    claims.reduce(
                      (total, claim) => total + claim.disputeAmount,
                      0,
                    ),
                  )}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Disputed
                </h3>
              </div>
              <div
                style={{ display: 'inline-block', float: 'right', margin: 20 }}
              >
                <div
                  style={{ fontSize: 24, textAlign: 'right', color: '#FFBB27' }}
                >
                  {formatNumber(claims.length)}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Claims
                </h3>
              </div>
              {/*<ResponsiveContainer
                style={{ position: 'absolute', zIndex: 999, right: 30 }}
                width='50%'
                height={100}
              >
                <PieChart>
                  <Pie
                    data={pieData}
                    fill='#8884d8'
                    paddingAngle={0} // No space between segments
                    dataKey='value'
                    nameKey={'name'}
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={numberFormatter} />
                </PieChart>
              </ResponsiveContainer>

              {/*<BarChart
                margin={{ top: 10, bottom: 93 }}
                width={500}
                height={200}
                data={monthClaimsGraphData}
              >
                <Tooltip formatter={moneyFormatter} />
                <Bar dataKey='disputeAmount' fill='#4C8EBA' />
              </BarChart>*/}
            </BoxWrapper>

            <BoxWrapper>
              <div
                style={{ display: 'inline-block', float: 'left', margin: 20 }}
              >
                <div
                  style={{ fontSize: 24, textAlign: 'left', color: '#2473A4' }}
                >
                  {formatDollars(
                    claims.reduce(
                      (total, claim) => total + claim.disputeAmount,
                      0,
                    ) -
                      reimbursedClaims.reduce(
                        (total, claim) => total + claim.disputeAmount,
                        0,
                      ),
                  )}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Pending
                </h3>
              </div>
              <div
                style={{ display: 'inline-block', float: 'right', margin: 20 }}
              >
                <div
                  style={{ fontSize: 24, textAlign: 'right', color: '#2473A4' }}
                >
                  {formatNumber(claims.length - reimbursedClaims.length)}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Claims
                </h3>
              </div>
              {/*<ResponsiveContainer
                style={{ position: 'absolute', zIndex: 999, right: 30 }}
                width='50%'
                height={100}
              >
                <PieChart>
                  <Pie
                    data={pieData}
                    fill='#8884d8'
                    paddingAngle={0} // No space between segments
                    dataKey='value'
                    nameKey={'name'}
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={numberFormatter} />
                </PieChart>
              </ResponsiveContainer>

              {/*<BarChart
                margin={{ top: 10, bottom: 93 }}
                width={500}
                height={200}
                data={monthClaimsGraphData}
              >
                <Tooltip formatter={moneyFormatter} />
                <Bar dataKey='disputeAmount' fill='#4C8EBA' />
              </BarChart>*/}
            </BoxWrapper>

            <BoxWrapper>
              <div
                style={{ display: 'inline-block', margin: 20, float: 'left' }}
              >
                <div
                  style={{
                    fontSize: 24,
                    textAlign: 'left',
                    color: '#82ca9d',
                  }}
                >
                  {formatDollars(totalReimbursed)}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Recovered
                </h3>
              </div>
              <div
                style={{ display: 'inline-block', float: 'right', margin: 20 }}
              >
                <div
                  style={{ fontSize: 24, textAlign: 'right', color: '#82ca9d' }}
                >
                  {formatNumber(reimbursedClaims.length)}
                </div>
                <h3
                  style={{
                    fontSize: 16,
                    paddingTop: 8,
                    textAlign: 'left',
                    margin: 0,
                    fontWeight: 400,
                  }}
                >
                  Total Claims
                </h3>
              </div>
              {/* <ResponsiveContainer
                style={{ position: 'absolute', zIndex: 999, left: 70 }}
                width='50%'
                height={100}
              >
                <PieChart>
                  <Pie
                    data={pie2Data}
                    fill='#8884d8'
                    paddingAngle={0} // No space between segments
                    dataKey='value'
                    nameKey={'name'}
                  >
                    {pie2Data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.color || COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={moneyFormatter} />
                </PieChart>
              </ResponsiveContainer> */}
              {/*<BarChart
                margin={{ top: 10, bottom: 93 }}
                width={500}
                height={200}
                data={monthClaimsGraphData}
              >
                <Tooltip formatter={moneyFormatter} />
                <Bar dataKey='disputeAmount' fill='#82ca9d' />
              </BarChart>*/}
            </BoxWrapper>
          </div>
          <Table
            style={{
              border: '2px solid #e5e5e5',
              boxShadow: '0px 1px 1px #e5e5e5',
              backgroundColor: 'white',
              borderRadius: 5,
            }}
          >
            <div>
              <Tr headerRow>
                <Th>Invoice #</Th>
                <Th>Purchase Order</Th>
                <Th>Invoice Date</Th>
                <Th>Invoice Amount</Th>
                <Th>Status</Th>
                <Th></Th>
              </Tr>
            </div>
            <div>
              {claims.slice(0, 100).map((claim) => {
                const showingDetails = openIds.includes(claim.id);
                return (
                  <>
                    <ClaimRow
                      style={{ cursor: 'pointer' }}
                      key={claim.id}
                      highlighted={showingDetails}
                      onClick={() => {
                        if (showingDetails) {
                          setOpenIds(openIds.filter((id) => id !== claim.id));
                          return;
                        } else {
                          setOpenIds([...openIds, claim.id]);
                        }
                      }}
                    >
                      <Td>{claim.invoiceNumber}</Td>
                      <Td>{claim.po}</Td>
                      <Td>{formatDate(claim.invoiceDate)}</Td>
                      <Td>${claim.invoiceAmount}</Td>
                      <Td>{mapStatus(claim.status)}</Td>
                      <ActionCell>
                        <ActionMenu />
                        <DashIcon
                          icon={showingDetails ? FaCaretUp : FaCaretDown}
                          size={30}
                          style={{}}
                        />
                      </ActionCell>
                    </ClaimRow>
                    {showingDetails ? <ClaimDetails claim={claim} /> : null}
                  </>
                );
              })}
            </div>
          </Table>
        </>
      )}
    </ClaimsWrapper>
  );
};

export default Claims;
