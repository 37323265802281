import styled from '@emotion/styled';
import Table from '../../components/Table';
import Tr from '../../components/Tr';
import Th from '../../components/Th';
import Td from '../../components/Td';
import { useMockData } from '../../contexts/MockDataContext';
import { startCase } from 'lodash';
import DashSelect from '../../components/DashSelect';
import { FaCaretUp } from 'react-icons/fa';
import AccountOverview from './components/AccountOverview';
import { FaCaretDown } from 'react-icons/fa';
import DashIcon from '../../components/DashIcon';
import ClaimsFilters from './components/ClaimsFilters';
import { useState } from 'react';
import ClaimDetails from './components/ClaimDetails';
import ActionMenu from './components/ActionMenu';
import Beiersdorf_Logo from '../../assets/Beiersdorf_Logo.svg';
import AmazonVendorCentralLogo from '../../assets/amazon_vendor_central.png';
import {
  formatDate,
  formatDollars,
  formatNumber,
} from '../../utils/formatting';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import SearchBox from '../../components/SearchBox';
import ExportClaims from './components/ExportClaims';

const AccountWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  gap: 1rem;
  margin-bottom: 20px;
`;
const BoxWrapper = styled.div`
  border: 2px solid #e5e5e5;
  box-shadow: 0px 1px 1px #e5e5e5;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  display: inline-block;
  background-color: white;
  padding-bottom: 5px;
`;

const CompanyIcon = styled.img`
  width: calc(850px / 4);
  height: calc(138px / 4);
`;

const ClaimsWrapper = styled.div`
  margin-top: 15px;
`;

const ActionCell = styled(Td)`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
`;

interface ClaimRowProps {
  highlighted?: boolean;
}

const ClaimRow = styled(Tr)<ClaimRowProps>`
  cursor: pointer;
  ${(props) =>
    props.highlighted &&
    `
    background-color: #f4f4f4;
    :hover {
      background-color: #f4f4f4;  
    }  
  `}
`;

interface StatusWrapperProps {
  paid?: boolean;
  rejected?: boolean;
  pending?: boolean;
}

const StatusWrapper = styled.span<StatusWrapperProps>`
  display: block;
  width: 120px;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  ${(props) => props.paid && `background-color: #EBF9EB; color: #5ACB57;`}
  ${(props) => props.rejected && `background-color: #FCD8CB; color: #F56B45;`}
  ${(props) => props.pending && `background-color: #FEF8E7; color: #F5C957;`}
`;
const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
}).format;

const InfoBox = ({
  title,
  amount,
  claimsRejected,
  claimsReimbursed,
  claimsPending,
}) => {
  const data = [
    {
      name: 'Claims Pending⠀⠀',
      value: claimsPending,
    },
    { name: 'Claims Reimbursed', value: claimsReimbursed },
    { name: 'Claims Rejected⠀⠀', value: claimsRejected },
  ];

  const COLORS = ['#FFBB28', '#00C49F', '#FF8042'];
  console.log('DATA: ', data);
  return (
    <BoxWrapper
      style={{
        position: 'relative',
        width: 'calc(50% - 10px)',
        height: 190,
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20,
        marginTop: 20,
        borderRadius: 5,
      }}
    >
      <div>
        <h3 style={{ padding: 0, margin: 0 }}>{title}</h3>
        <span
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: title === 'Shortage Disputes Recovered' ? '#00C49F' : null,
            marginTop: 20,
            display: 'block',
          }}
        >
          {amount}
        </span>
      </div>
      <ResponsiveContainer
        style={{ position: 'absolute', left: 200 }}
        width='50%'
        height={250}
      >
        <PieChart>
          <Pie
            data={data}
            fill='#8884d8'
            paddingAngle={0} // No space between segments
            dataKey='value'
            nameKey={'name'}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={numberFormatter} />
          <Legend
            layout='vertical'
            wrapperStyle={{
              position: 'absolute',
              textAlign: 'left',
              top: 110,
              left: 0,
              zIndex: -1,
            }}
            style={{
              textAlign: 'left',
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </BoxWrapper>
  );
};

const Escalation = () => {
  const [openIds, setOpenIds] = useState([]);
  //const navigate = useNavigate();
  /*const { data, loading } = useQuery(claims, {
    variables: { first: 10 },
    notifyOnNetworkStatusChange: true,
  });*/

  const { getClaims } = useMockData();
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    search: null,
    status: null,
  });
  const loading = false;
  const claims = getClaims(filter);
  const reimbursedClaims = claims.filter(
    (claim) => claim.status === 'Reimbursed',
  );
  const claimsRejected = claims.filter((claim) => claim.status === 'Rejected');
  const pendingClaims = claims.filter(
    (claim) => claim.status !== 'Rejected' && claim.status !== 'Reimbursed',
  );

  return (
    <ClaimsWrapper>
      {loading && <p>Loading...</p>}
      {!loading && (
        <>
          {/*<div
            style={{
              position: 'absolute',
              textAlign: 'center',
              right: 0,
              width: 200,
              height: 300,
              margin: 10,
            }}
          >
            <h2 style={{ margin: 0 }}>Connections</h2>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <img height={50} src={AmazonVendorCentralLogo} />
              <a href=''>Connect</a>
            </div>
            {Array(42)
              .fill(0)
              .map((_, i) => (
                <div
                  key={i} // Adding a key is important when rendering lists in React
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>Carrier {i + 1}</span>
                  <span style={{ color: '#00C49F' }}>Connected</span>
                </div>
              ))}
          </div>*/}
          {/*<AccountWrapper>
            <DashIcon icon={FaPen} onClick={() => {}} />
            <CompanyIcon
              src={Beiersdorf_Logo}
              alt='Beiersdorf_Logo'
              height={208}
              width={850}
            />
          </AccountWrapper>*/}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              verticalAlign: 'top',
              marginTop: 40,
            }}
          >
            <div
              style={{
                textAlign: 'left',
                fontSize: 20,
                fontWeight: 300,
                marginBottom: 30,
              }}
            >
              <div style={{ marginBottom: 5 }}>
                Start Date:{' '}
                <span style={{ fontWeight: 400 }}>Dec 10, 2023</span>
              </div>
              <div>
                {' '}
                End Date:{' '}
                <span style={{ fontWeight: 400, paddingLeft: 9 }}>
                  {' '}
                  Mar 10, 2024
                </span>
              </div>
            </div>
            <SearchBox
              setSearch={(value) => {
                setFilter((filter) => ({ ...filter, search: value }));
              }}
            ></SearchBox>
          </div>
          <div
            style={{
              marginBottom: 30,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              gap: 25,
            }}
          >
            <BoxWrapper style={{ textAlign: 'center', paddingTop: 20 }}>
              <span style={{ fontWeight: 500, fontSize: 24 }}>
                {formatDollars(
                  claims.reduce(
                    (total, claim) => total + claim.disputeAmount,
                    0,
                  ),
                )}
              </span>
              <h2 style={{ marginTop: 10, fontWeight: 300, fontSize: 18 }}>
                Total Value
              </h2>
            </BoxWrapper>
            <BoxWrapper style={{ textAlign: 'center', paddingTop: 20 }}>
              <span style={{ fontWeight: 500, fontSize: 24 }}>
                {formatNumber(claims.length)}
              </span>
              <h2 style={{ marginTop: 10, fontWeight: 300, fontSize: 18 }}>
                Total Claims
              </h2>
            </BoxWrapper>
            <BoxWrapper style={{ textAlign: 'center', paddingTop: 20 }}>
              <span style={{ fontWeight: 500, fontSize: 24, color: '#82ca9d' }}>
                {formatDollars(
                  claims.reduce(
                    (total, claim) => total + claim.disputeAmount,
                    0,
                  ) * 0.9,
                )}
              </span>
              <h2
                style={{
                  marginTop: 10,
                  fontWeight: 300,
                  fontSize: 18,
                }}
              >
                Recovered Value
              </h2>
            </BoxWrapper>
          </div>

          <BoxWrapper>
            <Table
              style={{
                backgroundColor: 'white',
              }}
            >
              <div>
                <Tr headerRow>
                  <Th>Document #</Th>
                  <Th>Document Date</Th>
                  <Th>Item Balance</Th>
                  <Th>Amazon Document #</Th>
                  <Th style={{ justifyContent: 'right' }}>
                    <a
                      style={{
                        color: '#2473A4',
                        position: 'relative',
                        right: 5,
                      }}
                      href=''
                    >
                      Export
                    </a>
                  </Th>
                </Tr>
              </div>
              <div>
                {claims.slice(0, 100).map((claim) => {
                  const showingDetails = openIds.includes(claim.id);
                  return (
                    <>
                      <ClaimRow
                        style={{ cursor: 'pointer' }}
                        key={claim.id}
                        highlighted={showingDetails}
                        onClick={() => {
                          if (showingDetails) {
                            setOpenIds(openIds.filter((id) => id !== claim.id));
                            return;
                          } else {
                            setOpenIds([...openIds, claim.id]);
                          }
                        }}
                      >
                        <Td>{claim.invoiceNumber}</Td>
                        <Td>{formatDate(claim.invoiceDate)}</Td>
                        <Td>${claim.disputeAmount}</Td>
                        <Td>{claim.po}</Td>
                        <ActionCell>
                          <DashIcon
                            icon={showingDetails ? FaCaretUp : FaCaretDown}
                            size={30}
                          />
                        </ActionCell>
                      </ClaimRow>
                      {showingDetails ? <ClaimDetails claim={claim} /> : null}
                    </>
                  );
                })}
              </div>
            </Table>
          </BoxWrapper>
        </>
      )}
    </ClaimsWrapper>
  );
};

export default Escalation;
