import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Legend,
  Bar,
} from 'recharts';
import { useState } from 'react';
import DashDatePicker from '../../../components/DashDatePicker';
import { getMonthsBetween } from '../../../utils/getMonthsBetween';
import { useMockData } from '../../../contexts/MockDataContext';

const IssueTrends = ({ startDate, endDate }) => {
  const { getClaims } = useMockData();

  const claims = getClaims({
    startDate,
    endDate,
  });

  function aggregateClaimsByIssue(claims) {
    const issueData = {};
    claims.forEach((claim) => {
      const issue = claim.issue; // Ensure this is the correct field for issue
      if (!issueData[issue]) {
        issueData[issue] = {
          issue: issue,
          pendingAmount: 0,
          reimbursedAmount: 0,
          rejectedAmount: 0,
        };
      }
      console.log('CLAIM: ', claim.status);
      issueData[issue].disputeAmount += 0.1;
      if (claim.status === 'Reimbursed') {
        issueData[issue].reimbursedAmount += 1;
      } else if (claim.status === 'Rejected') {
        issueData[issue].rejectedAmount += 1;
      } else {
        issueData[issue].pendingAmount += 1;
      }
    });
    console.log(issueData);
    return Object.values(issueData);
  }
  const data = aggregateClaimsByIssue(claims);
  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
  }).format;

  return (
    <div style={{ display: 'inline-block' }}>
      <h2>Top Issue Trends</h2>
      <ResponsiveContainer width={500} height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='issue' />
          <YAxis tickFormatter={numberFormatter} />
          <Tooltip formatter={(value) => numberFormatter(value)} />
          <Legend />
          <Bar
            dataKey='reimbursedAmount'
            stackId='a'
            fill='#82ca9d'
            name='Reimbursed'
          />
          <Bar
            dataKey='rejectedAmount'
            stackId='a'
            fill='#FE5C36'
            name='Rejected'
          />
          <Bar
            dataKey='pendingAmount'
            stackId='a'
            fill='#8884d8'
            name='Pending'
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default IssueTrends;
