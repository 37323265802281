import { useLocation, useNavigate } from 'react-router-dom';

const useActiveTab = (
  mainPath: string = '/',
): [string, (tabName: string) => void] => {
  const location = useLocation();
  const navigate = useNavigate();
  const setActiveTab = (tabName: string) => {
    navigate(`${mainPath}${tabName}`);
  };
  return [location.pathname.split('/').pop(), setActiveTab];
};
export default useActiveTab;
