import { useState } from 'react';
import DashSelect from '../../../components/DashSelect';
import { useMockData } from '../../../contexts/MockDataContext';
import { startCase } from 'lodash';
import SearchBox from '../../../components/SearchBox';
import FilterWrapper from '../../../components/FilterWrapper';
import DashDatePicker from '../../../components/DashDatePicker';

const DocumentsFilters = ({ setFilter }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const { getDocumentStatuses } = useMockData();
  const [startDate, endDate] = dateRange;
  const statusOptions = getDocumentStatuses().map((status) => ({
    value: status,
    label: startCase(status),
  }));

  return (
    <FilterWrapper>
      <DashDatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
          setFilter((filter) => ({
            ...filter,
            startDate: update[0],
            endDate: update[1],
          }));
        }}
      />
      <DashSelect
        options={statusOptions}
        onChange={(update) => {
          setFilter((filter) => ({ ...filter, status: update?.value }));
        }}
        isClearable
      />
      <SearchBox
        setSearch={(value) => {
          setFilter((filter) => ({ ...filter, search: value }));
        }}
      ></SearchBox>
    </FilterWrapper>
  );
};

export default DocumentsFilters;
