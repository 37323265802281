import styled from '@emotion/styled';

const Td = styled.div`
  justify-content: left;
  align-items: center;
  font-weight: 400;
  text-align: left;
  display: flex;
  flex: 1 1 0%;
  padding: 8px;
`;
export default Td;