import React, { ReactNode } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import Login from '../screens/Login';
import Documents from '../screens/Documents';
import Claims from '../screens/Claims';
import Accounts from '../screens/Accounts';
import Settings from '../screens/Settings';
import { ModalProvider } from '../contexts/ModalContext';
import { CurrentUserProvider } from '../contexts/UserContext';
import { InternalApolloProvider } from '../contexts/ApolloContext';
import { NotificationsProvider } from '../contexts/NotificationsContext';
import { AuthProvider, useAuth } from '../contexts/AuthProvider';

import { ErrorBoundary } from '@highlight-run/react';
import { H } from 'highlight.run';
import { MockDataProvider } from '../contexts/MockDataContext';
import PageWrapper from './PageWrapper';
import { Global, css } from '@emotion/react';
import ReportingDemo from '../screens/ReportingDemo';
import { UploadManagerProvider } from '../contexts/UploadManagerContext';
import Overview from '../screens/Overview/index';
import ClaimsDemo from '../screens/ClaimsDemo';
import Escalation from '../screens/Escalation';

const highlightId = import.meta.env.VITE_HIGHLIGHT_ID;

const GlobalStyles = css`
  html,
  body,
  #root {
    font-family: roboto, sans-serif;
    margin: 0;
    padding: 0;
  }
  #popup-root {
  }
`;

if (highlightId) {
  H.init(highlightId, {
    serviceName: 'frontend-app',
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        'https://www.googleapis.com/identitytoolkit',
        'https://securetoken.googleapis.com',
      ],
    },
  });
}

const AuthWrapper: React.FC<{
  children?: ReactNode;
  isAuthenticatedView?: boolean;
  authRedirect?: string;
}> = ({ isAuthenticatedView = true, authRedirect = '/', children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticatedView && !isAuthenticated) {
    return <Navigate to='/login' replace />;
  }
  if (!isAuthenticatedView && isAuthenticated) {
    return <Navigate to={authRedirect} replace />;
  }
  if (!isAuthenticatedView) {
    return children;
  }
  return <PageWrapper>{children}</PageWrapper>;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthWrapper>
        <Overview />
      </AuthWrapper>
    ),
  },
  {
    path: '/claims',
    element: (
      <AuthWrapper>
        <ClaimsDemo />
      </AuthWrapper>
    ),
  },
  {
    path: '/disputes',
    element: (
      <AuthWrapper>
        <ClaimsDemo />
      </AuthWrapper>
    ),
  },
  {
    path: '/accounts',
    element: (
      <AuthWrapper>
        <Accounts />
      </AuthWrapper>
    ),
  },
  {
    path: '/documents',
    element: (
      <AuthWrapper>
        <Documents />
      </AuthWrapper>
    ),
  },
  {
    path: 'escalation',
    element: (
      <AuthWrapper>
        <Escalation />
      </AuthWrapper>
    ),
  },
  {
    path: '/settings*',
    element: (
      <AuthWrapper>
        <Settings />
      </AuthWrapper>
    ),
  },
  {
    path: '/reporting*',
    element: (
      <AuthWrapper>
        <ReportingDemo />
      </AuthWrapper>
    ),
  },
  {
    path: '/account/*',
    element: (
      <AuthWrapper>
        <ClaimsDemo />
      </AuthWrapper>
    ),
  },
  {
    path: '/login',
    element: (
      <AuthWrapper isAuthenticatedView={false}>
        <Login />
      </AuthWrapper>
    ),
  },
  {
    path: '*',
    element: <Navigate to='/' replace />,
  },
]);

function App() {
  return (
    <ErrorBoundary>
      <Global styles={GlobalStyles} />
      <MockDataProvider>
        <AuthProvider>
          <NotificationsProvider>
            <InternalApolloProvider>
              <CurrentUserProvider>
                <ModalProvider>
                  <UploadManagerProvider>
                    <RouterProvider router={router} />
                  </UploadManagerProvider>
                </ModalProvider>
              </CurrentUserProvider>
            </InternalApolloProvider>
          </NotificationsProvider>
        </AuthProvider>
      </MockDataProvider>
    </ErrorBoundary>
  );
}

export default App;
