import React, { useState } from 'react';
import styled from '@emotion/styled';
import { gql, useMutation } from '@apollo/client';
import { useAuth } from '../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { H } from 'highlight.run';
import logo from '../assets/logo.svg';
import logoSquare from '../assets/logo_square.svg';
import arrows from '../assets/arrows.svg';

const signInMutation = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      token
      user {
        id
        email
      }
    }
  }
`;

const MainContainer = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: calc(100vh - 40px);
  padding-left: 10vw;
  background: linear-gradient(
    180deg,
    #2a7dae 7.55%,
    rgba(58, 147, 200, 0.8) 51.93%,
    rgba(41, 127, 178, 0.6) 96.32%,
    rgba(30, 107, 153, 0.4) 140.7%,
    rgba(15, 95, 143, 0.2) 185.09%,
    rgba(35, 88, 119, 0) 229.47%
  );
`;

const FormContainer = styled.div`
  width: 496px;
  padding: 10px;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 20px 100px;
  border-radius: 18px;
  background-color: white;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
  align-self: flex-start;
  font-weight: 400;
  color: #777777;
`;

const Input = styled.input`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`;

const Button = styled.button`
  padding: 10px 0;
  border: none;
  border-radius: 4px;
  background-color: blue;
  color: white;
  cursor: pointer;
  width: 100%;
  width: 286px;
  height: 50px;
  background: linear-gradient(
    180deg,
    #a6ce81 77.72%,
    rgba(166, 206, 129, 0.5) 116%
  );
  border: 0.5px solid #98b57c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 30px;
  margin-top: 40px;
  margin-bottom: 30px;
`;

const SquareLogo = styled.img`
  margin-left: calc(50% - 40px);
  width: 80px;
  height: 80px;
`;

const WelcomeText = styled.h2`
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 10px;
`;

const WelcomeSubText = styled.span`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  color: #777777;
  display: block;
`;

const Error = styled.p`
  color: red;
  line-height: 16px;
  display: block;
  height: 16px;
`;

const ContactText = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  padding-bottom: 50px;
  display: block;
`;

const ContextLink = styled.a`
  color: #231f20;
`;

const ArrowsImg = styled.img`
  margin-right: 10vw;
  margin-left: 5vw;
`;

const CenterDiv = styled.div`
  text-align: center;
`;
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signIn] = useMutation(signInMutation);
  const { setAuth } = useAuth();

  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (email.length === 0 || password.length === 0) {
      setErrorMessage('Email and password are required');
      return;
    }
    try {
      const response = await signIn({
        variables: { input: { email, password } },
      });
      setAuth(response.data.signIn.token);
      H?.identify(response.data.signIn.user.email, {
        id: response.data.signIn.user.id,
      });
      navigate('/');
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <MainContainer>
      <div>
        <img src={logo} width={115} alt='Dash' />
        <FormContainer>
          <SquareLogo src={logoSquare} width={80} alt='Dash' />
          <WelcomeText>Welcome back</WelcomeText>
          <WelcomeSubText>Let's get you signed in</WelcomeSubText>
          <Form onSubmit={handleSubmit}>
            <Label htmlFor='email'>Email</Label>
            <Input
              id='email'
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Label htmlFor='password'>Password</Label>
            <Input
              id='password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Error>{errorMessage}</Error>
            <Button type='submit'>Login</Button>
          </Form>
          <CenterDiv>
            <ContactText>
              Don’t have an account?{' '}
              <ContextLink
                href='mailto:team@joindash.io'
                target='_blank'
                rel='noreferrer'
              >
                Contact Us
              </ContextLink>
            </ContactText>
          </CenterDiv>
        </FormContainer>
      </div>
      <ArrowsImg src={arrows} width={500} alt='arrows' />
    </MainContainer>
  );
};

export default Login;
