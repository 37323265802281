import React, { useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import styled from '@emotion/styled';
import DashIcon from './DashIcon';

// Styled components
const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  width: 256px;
  height: 36px;
  float: right;
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  padding: 8px 32px 8px 12px;
  font-size: 16px;
  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  padding: 8px;
  cursor: pointer;
`;

const ClearWrapper = styled(IconWrapper)`
  right: 25px;
`;

const SearchBox = ({ setSearch }) => {
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const clearInput = () => {
    setInputValue('');
    setSearchValue('');
    setSearch(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchValue(inputValue);
    setSearch(inputValue);
  };

  return (
    <form
      style={{
        float: 'right',
        width: '50%',
      }}
      onSubmit={handleSubmit}
    >
      <SearchWrapper>
        <SearchInput
          type='text'
          placeholder='Search'
          value={inputValue}
          onChange={handleInputChange}
        />
        {searchValue && (
          <ClearWrapper onClick={clearInput}>
            <DashIcon icon={FaTimes} />
          </ClearWrapper>
        )}
        <IconWrapper type='submit'>
          <DashIcon icon={FaSearch} />
        </IconWrapper>
      </SearchWrapper>
    </form>
  );
};

export default SearchBox;
