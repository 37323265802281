import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import NotificationBar from '../components/NotificationBar';

interface NotificationOptions {
  hideClose?: boolean;
  persist?: boolean;
  duration?: number;
  type?: 'default' | 'error' | 'warning' | 'success';
}
// Notification context
const NotificationsContext = createContext({
  showNotification: (message, options = {}) => {},
  showError: (message, options = {}) => {},
  showWarning: (message, options = {}) => {},
  showSuccess: (message, options = {}) => {},
});

// Notification provider
export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const removeNotification = useCallback((id) => {
    setNotifications((current) =>
      current.filter((notification) => notification.id !== id),
    );
  }, []);

  const showNotification = useCallback(
    (message, options: NotificationOptions = {}) => {
      const {
        hideClose = false,
        persist = false,
        duration = 10000,
        type = 'default',
      } = options;

      setNotifications((currentNotifications) => {
        // Check if a notification with the same message and type already exists
        const notificationExists = currentNotifications.some(
          (notification) =>
            notification.message === message && notification.type === type,
        );

        if (!notificationExists) {
          const id = new Date().getTime(); // Simple unique ID
          const notification = {
            id,
            message,
            hideClose,
            persist,
            type,
            duration,
          };

          if (!persist) {
            setTimeout(() => removeNotification(id), duration);
          }

          return [...currentNotifications, notification];
        }

        // Return current state unchanged if notification exists
        return currentNotifications;
      });
    },
    [removeNotification],
  );
  const showTypeNotification = useCallback(
    (type) =>
      (message, options = {}) => {
        const baseOptions = { ...options, type };
        showNotification(message, baseOptions);
      },
    [showNotification],
  );

  const showError = showTypeNotification('error');
  const showWarning = showTypeNotification('warning');
  const showSuccess = showTypeNotification('success');

  const value = useMemo(
    () => ({ showNotification, showError, showWarning, showSuccess }),
    [showError, showNotification, showSuccess, showWarning],
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
      <NotificationBar
        notifications={notifications}
        removeNotification={removeNotification}
      />
    </NotificationsContext.Provider>
  );
};

// Custom hook to use notifications
export const useNotifications = () => useContext(NotificationsContext);
