import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { gql, useMutation } from '@apollo/client';
import { partial } from 'lodash';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { useNotifications } from '../../../../contexts/NotificationsContext';
import CompanyForm from './components/CompanyForm';
import { useModal } from '../../../../contexts/ModalContext';

// Define your styled components here
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: #f0f0f0;
  color: #333;
  padding: 8px;
  border: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #f4f4f4;
  }
`;

const companiesQuery = gql`
  query CompaniesQuery($after: String, $first: Int = 10) {
    companies(first: $first, after: $after) {
      edges {
        node {
          id
          name
          type
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const deleteCompanyMutation = gql`
  mutation deleteCompany($input: DeleteCompanyInput!) {
    deleteCompany(input: $input) {
      company {
        id
      }
    }
  }
`;

const Companies = () => {
  const { data, fetchMore, loading } = useQuery(companiesQuery, {
    variables: { first: 10 },
    notifyOnNetworkStatusChange: true,
  });

  const fetchMoreCompanies = () => {
    const { endCursor } = data.companies.pageInfo;
    fetchMore({
      variables: { after: endCursor },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.companies.edges;
        const pageInfo = fetchMoreResult.companies.pageInfo;

        return newEdges.length
          ? {
              companies: {
                __typename: prevResult.companies.__typename,
                edges: [...prevResult.companies.edges, ...newEdges],
                pageInfo,
              },
            }
          : prevResult;
      },
    });
  };

  const { showError } = useNotifications();
  const { showModal } = useModal();
  const [deleteCompany] = useMutation(deleteCompanyMutation, {
    onError: (error) => {
      // Optionally handle the error more specifically
      console.log('error: ', error);
      showError('Error deleting user');
    },
  });

  const deleteCompanyCallback = (id) => {
    deleteCompany({
      variables: { input: { id } },
      update: (cache) => {
        // Read the current data from the cache for the query you want to update
        const existingCompanies = cache.readQuery({ query: companiesQuery });
        if (existingCompanies && existingCompanies.companies) {
          // Filter out the deleted user
          const updatedCompanies = existingCompanies.companies.edges.filter(
            (user) => user.id !== id,
          );
          // Write the updated list back to the cache
          cache.writeQuery({
            query: companiesQuery,
            data: { companies: updatedCompanies },
          });
        }
      },
    });
  };

  const addCompany = () => {
    showModal(<CompanyForm />);
  };

  const editCompany = (id) => {
    showModal(<CompanyForm companyId={id} />);
  };

  return (
    <div>
      <h2>Companies</h2>
      <button onClick={addCompany}>Create Company</button>
      {loading && <p>Loading...</p>}
      {!loading && (
        <InfiniteScroll
          dataLength={data.companies.edges.length}
          next={fetchMoreCompanies}
          hasMore={data.companies.pageInfo.hasNextPage}
          loader={<h4>Loading...</h4>}
          endMessage={<p>End of companies list</p>}
        >
          <Table>
            <thead>
              <Tr>
                <Th>Name</Th>
                <Th>Type</Th>
                <Th></Th>
              </Tr>
            </thead>
            <tbody>
              {data.companies.edges.map(({ node }) => (
                <Tr key={node.id}>
                  <Td>{node.name}</Td>
                  <Td>{node.type}</Td>
                  <Td>
                    <button onClick={partial(editCompany, node.id)}>
                      <FaEdit />
                    </button>
                    <button onClick={partial(deleteCompanyCallback, node.id)}>
                      <FaTrash />
                    </button>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default Companies;
