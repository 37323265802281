const NotificationBar = ({ notifications, removeNotification }) => {
  console.log(notifications);
  return (
    <div style={{ position: 'fixed', top: 0, right: 0, zIndex: 2 }}>
      {notifications.map((notification) => (
        <div
          key={notification.id}
          style={{
            backgroundColor: getBackgroundColor(notification.type),
            margin: '5px',
            padding: '10px',
          }}
        >
          {notification.message}
          {!notification.hideClose && (
            <button
              onClick={() => removeNotification(notification.id)}
              style={{ marginLeft: '10px' }}
            >
              X
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

const getBackgroundColor = (type) => {
  switch (type) {
    case 'error':
      return 'red';
    case 'warning':
      return 'orange';
    case 'success':
      return 'green';
    default:
      return 'gray';
  }
};

export default NotificationBar;
