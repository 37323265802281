import Select from 'react-select';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';

const StyledSelect = styled(Select)`
  width: 256px;
  cursor: pointer;
  border-radius: 5px;
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '5px',
    // Add other custom styles here as needed, e.g., border color
  }),
};

const DashSelect = (props: ComponentProps<Select>) => {
  return <StyledSelect styles={customStyles} {...props} />;
};

export default DashSelect;
