import styled from '@emotion/styled';
import { startCase } from 'lodash';
import { useEffect } from 'react';
import useActiveTab from '../hooks/useActiveTab';
const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  gap: 2px;
  width: 250px;
  text-align: left;
  align-items: flex-start;
  min-height: 300px;
`;

const TabButton = styled.button`
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-weight: ${(props: { active: boolean }) =>
    props.active ? 'bold' : 'normal'};
  color: ${(props: { active: boolean }) => (props.active ? '#007bff' : '#000')};

  &:focus {
    outline: none;
  }
`;

const SideNav = ({ mainRoute, routes }) => {
  const [activeTab, setActiveTab] = useActiveTab(mainRoute);

  useEffect(() => {
    if (!routes.includes(activeTab)) {
      setActiveTab(routes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SideContainer>
      {routes.map((route) => (
        <TabButton
          onClick={() => setActiveTab(route)}
          active={activeTab === route}
        >
          {startCase(route)}
        </TabButton>
      ))}
    </SideContainer>
  );
};
export default SideNav;
