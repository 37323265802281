import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useCurrentUser } from '../../../../../../contexts/UserContext';
import Modal from '../../../../../../components/modal';
import { useModal } from '../../../../../../contexts/ModalContext';
import { useNotifications } from '../../../../../../contexts/NotificationsContext';
import { useState } from 'react';
import { isEmpty } from 'lodash';

// Emotion styled components
const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Label = styled('label')`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

const Input = styled('input')`
  margin-top: 5px;
  padding: 10px;
  font-size: 16px;
`;

const Select = styled('select')`
  margin-top: 5px;
  padding: 10px;
  font-size: 16px;
`;

const Button = styled('button')`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;
// Emotion styled components (no changes from previous examples)

const updateUsersMutation = gql`
  mutation UserFormUpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        name
        email
      }
    }
  }
`;

const getUserQuery = gql`
  query UserFormUserQuery($id: ID!) {
    node(id: $id) {
      ... on User {
        id
        name
        email
        company {
          id
          name
        }
        admin
      }
    }
  }
`;

const createUserMutation = gql`
  mutation UserFormCreateUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        name
        email
      }
    }
  }
`;

const getCompaniesQuery = gql`
  query UserFormCompaniesQuery($first: Int, $after: String) {
    companies(first: $first, after: $after) {
      nodes {
        id
        name
      }
    }
  }
`;

const CreateEditUserForm = ({ userId }: { userId?: string }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { data: userData, loading: userLoading } = useQuery(getUserQuery, {
    variables: { id: userId },
    skip: !userId,
  });
  const { currentUser } = useCurrentUser();
  const { data: companiesData, loading: companiesLoading } = useQuery(
    getCompaniesQuery,
    {
      skip: !currentUser?.superAdmin,
    },
  );
  const [createUser, { loading: creating }] = useMutation(createUserMutation);
  const [updateUser, { loading: updating }] = useMutation(updateUsersMutation);
  const [initialValues, setInitialValues] = useState({});
  const { closeModal } = useModal();
  const { showError, showSuccess, showNotification } = useNotifications();

  console.log('USER DATA: ', userData);
  // Initialize form with user data when editing
  useEffect(() => {
    if (userData) {
      const newFormData = {
        name: userData.node.name,
        email: userData.node.email,
        companyId: userData.node.company.id,
        admin: userData.node.admin,
      };
      setInitialValues(newFormData);
      reset(newFormData);
    }
  }, [userData, reset]);

  const onSubmit = (formData) => {
    console.log('FORM DATA: ', formData);
    const operation = userId ? updateUser : createUser;
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialValues[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (isEmpty(changes)) {
      showNotification('No changes made');
      closeModal();
      return;
    }
    const { name, email, admin } = changes;

    operation({
      variables: {
        input: userId ? { id: userId, name, email, admin } : formData,
      },
    })
      .then((response) => {
        // Handle success
        console.log('User saved:', response.data);
        showSuccess('User saved');
        closeModal();
      })
      .catch((error) => {
        // Handle error
        console.error('Error saving user:', error);
        showError('Error saving user');
      });
  };

  console.log('CURRENT USER: ', currentUser, companiesLoading, userLoading);
  if (!currentUser || companiesLoading || userLoading) return <p>Loading...</p>;

  console.log('COMPANIES DATA: ', companiesData);
  return (
    <Modal>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Form fields */}
        <Label>
          Name:
          <Input {...register('name', { required: true })} />
          {errors.name && <span>This field is required</span>}
        </Label>
        <Label>
          Email:
          <Input {...register('email', { required: true })} type='email' />
          {errors.email && <span>This field is required</span>}
        </Label>

        {currentUser.superAdmin && !userId && (
          <Label>
            Company:
            <Select {...register('companyId', { required: true })}>
              <option value={''}>- select - </option>
              {companiesData.companies.nodes.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Select>
          </Label>
        )}

        <Label>
          <Input {...register('admin')} type='checkbox' />
          Admin
        </Label>
        <Button type='submit' disabled={creating || updating}>
          {userId ? 'Update User' : 'Create User'}
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateEditUserForm;
