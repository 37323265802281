import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { useMutation, useQuery, gql } from '@apollo/client';
//import { useCurrentCompany } from '../../../../../../contexts/CompanyContext';
import Modal from '../../../../../../components/modal';
import { useModal } from '../../../../../../contexts/ModalContext';
import { useNotifications } from '../../../../../../contexts/NotificationsContext';
import { useState } from 'react';
import { isEmpty } from 'lodash';

// Emotion styled components
const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Label = styled('label')`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

const Input = styled('input')`
  margin-top: 5px;
  padding: 10px;
  font-size: 16px;
`;

const Select = styled('select')`
  margin-top: 5px;
  padding: 10px;
  font-size: 16px;
`;

const Button = styled('button')`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;
// Emotion styled components (no changes from previous examples)

const updateCompanysMutation = gql`
  mutation CompanyFormUpdateCompanyMutation($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        id
        name
        type
      }
    }
  }
`;

const getCompanyQuery = gql`
  query CompanyFormCompanyQuery($id: ID!) {
    node(id: $id) {
      ... on Company {
        id
        name
        type
      }
    }
  }
`;

const createCompanyMutation = gql`
  mutation CompanyFormCreateCompanyMutation($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      company {
        id
        name
        type
      }
    }
  }
`;

/*
const getCompaniesQuery = gql`
  query CompanyFormCompaniesQuery($first: Int, $after: String) {
    companies(first: $first, after: $after) {
      nodes {
        id
        name
      }
    }
  }
`;
*/
const CreateEditCompanyForm = ({ companyId }: { companyId?: string }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { data: companyData, loading: companyLoading } = useQuery(
    getCompanyQuery,
    {
      variables: { id: companyId },
      skip: !companyId,
    },
  );
  const [createCompany, { loading: creating }] = useMutation(
    createCompanyMutation,
  );
  const [updateCompany, { loading: updating }] = useMutation(
    updateCompanysMutation,
  );
  const [initialValues, setInitialValues] = useState({});
  const { closeModal } = useModal();
  const { showError, showSuccess, showNotification } = useNotifications();

  // Initialize form with company data when editing
  useEffect(() => {
    if (companyData) {
      const newFormData = {
        name: companyData.node.name,
        type: companyData.node.type || 'VendorCompany',
      };
      setInitialValues(newFormData);
      reset(newFormData);
    }
  }, [companyData, reset]);

  const onSubmit = (formData) => {
    console.log('FORM DATA: ', formData);
    const operation = companyId ? updateCompany : createCompany;
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialValues[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (isEmpty(changes)) {
      showNotification('No changes made');
      closeModal();
      return;
    }
    const { name } = changes;

    operation({
      variables: { input: companyId ? { id: companyId, name } : formData },
    })
      .then((response) => {
        // Handle success
        console.log('Company saved:', response.data);
        showSuccess('Company saved');
        closeModal();
      })
      .catch((error) => {
        // Handle error
        console.error('Error saving company:', error);
        showError('Error saving company');
      });
  };

  if (companyLoading) return <p>Loading...</p>;

  return (
    <Modal>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Form fields */}
        <Label>
          Name:
          <Input {...register('name', { required: true })} />
          {errors.name && <span>This field is required</span>}
        </Label>

        <Label>
          Type:
          <Select {...register('type', { required: true })}>
            <option value={'VendorCompany'}>Vendor Company</option>
          </Select>
        </Label>

        <Button type='submit' disabled={creating || updating}>
          {companyId ? 'Update Company' : 'Create Company'}
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateEditCompanyForm;
