import styled from '@emotion/styled';
import SideNav from '../../components/SideNav';
import useActiveTab from '../../hooks/useActiveTab';

import MonthlyClaims from './components/MonthlyClaims';
import QuarterlyClaims from './components/QuarterlyClaims';
import MonthlyClaimsNumbers from './components/MonthlyClaimsNumbers';
import QuarterlyClaimsNumbers from './components/QuarterlyClaimsNumbers';
import Reimbursed from './components/Reimbursed';
import { useState } from 'react';
import { getMonthsBetween } from '../../utils/getMonthsBetween';
import DashDatePicker from '../../components/DashDatePicker';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import IssueTrends from './components/IssueTrends';
import ClaimBreakdown from './components/ClaimBreakdown';
import Projections from './components/Projections';
import { useMockData } from '../../contexts/MockDataContext';
import { formatDollars, formatNumber } from '../../utils/formatting';
import YearlyClaims from './components/YearlyClaims';

const Container = styled.div``;

const ContentArea = styled.div`
  width: 100%;
`;

const Reporting = () => {
  const [activeTab] = useActiveTab();

  // Function to render the active tab's content
  const renderContent = () => {
    switch (activeTab) {
      case 'monthlyClaims':
        return <MonthlyClaims />;
      case 'quarterlyClaims':
        return <QuarterlyClaims />;
      case 'monthlyClaimsNumbers':
        return <MonthlyClaimsNumbers />;
      case 'quarterlyClaimsNumbers':
        return <QuarterlyClaimsNumbers />;
      case 'reimbursed':
        return <Reimbursed />;
      default:
        return <></>;
    }
  };

  const [dateRange, setDateRange] = useState([
    new Date(2023, 0, 1),
    new Date(2024, 0, 1),
  ]);
  const [startDate, endDate] = dateRange;

  const months = getMonthsBetween(startDate, endDate);
  const { getClaims } = useMockData();
  const claims = getClaims({
    startDate,
    endDate,
  });

  const underThreshold = claims.reduce((acc, claim) => {
    if (claim.disputeAmount < 300 && claim.status === 'Reimbursed') {
      return acc + claim.disputeAmount;
    }
    return acc;
  }, 0);

  return (
    <Container>
      <div style={{ width: '100%' }}>
        <DashDatePicker
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
          }}
        />
      </div>
      <br />
      <div
        style={{
          fontSize: '12px',
          display: 'inline-grid',
          gridTemplateColumns: 'auto auto auto',
        }}
      >
        <IssueTrends startDate={startDate} endDate={endDate} />
        <ClaimBreakdown
          startDate={startDate}
          endDate={endDate}
          title='First Round Dispute'
        />
        <ClaimBreakdown
          startDate={startDate}
          endDate={endDate}
          title='PQV Escalations'
        />
        <Projections></Projections>
        <div>
          <h2>Claims Reimbursed Under 300</h2>
          <span style={{ color: '#006600' }}>
            {formatDollars(underThreshold)}
          </span>
        </div>
        <div>
          <h2>BOLs / Supporting documents collected</h2>
          <span style={{ color: '#006600' }}>
            {formatNumber(claims.length)}
          </span>
        </div>

        <div>
          <h2>Claims Disputed</h2>
          <span>(compared to before Dash)</span>
          <span>42% disputes submitted</span>
          <span>$287,000</span>
        </div>
        <YearlyClaims startDate={startDate} endDate={endDate} />
      </div>
    </Container>
  );
};

export default Reporting;
