import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Legend,
  Bar,
  PieChart,
  Pie,
  Cell,
  Label,
} from 'recharts';
import { useState } from 'react';
import DashDatePicker from '../../../components/DashDatePicker';
import { getMonthsBetween } from '../../../utils/getMonthsBetween';
import { useMockData } from '../../../contexts/MockDataContext';

const YearlyClaims = () => {
  const { getClaims } = useMockData();

  const claims = getClaims();
  function aggregateClaimsByMonthAndYear(claims) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const aggregatedData = {};
    claims.forEach((claim) => {
      const date = new Date(claim.invoiceDate);
      const year = date.getFullYear();
      const month = monthNames[date.getMonth()];

      if (!aggregatedData[year]) {
        aggregatedData[year] = {};
        monthNames.forEach((month) => {
          aggregatedData[year][month] = 0;
        });
      }

      aggregatedData[year][month] += claim.disputeAmount;
    });

    const result = monthNames.map((month) => {
      const obj = { month };
      Object.keys(aggregatedData).forEach((year) => {
        obj[year] = aggregatedData[year][month];
      });
      return obj;
    });

    return result;
  }

  const data = aggregateClaimsByMonthAndYear(claims);
  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format;
  const COLORS = ['#00C49F', '#FFBB28', '#0088FE', '#FF8042'];
  const years = Object.keys(data[0]).filter((key) => key !== 'month');
  return (
    <div style={{ width: '1500px' }}>
      <span style={{ fontSize: '24px', fontWeight: 'bold' }}>24</span>
      <ResponsiveContainer width='100%' height={400}>
        <LineChart
          data={data}
          margin={{ top: 20, right: 30, left: 200, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='month' />
          <YAxis tickFormatter={numberFormatter} />
          <Tooltip />
          <Legend />
          {years.map((year) => (
            <Line
              key={year}
              type='monotone'
              dataKey={year}
              name={year}
              stroke={COLORS[years.indexOf(year)]}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default YearlyClaims;
