export function formatDollars(amount) {
  return `$${Math.round(amount).toString().replace(/\d(?=(\d{3})+$)/g, '$&,')}`;
}

export function formatDate(date) {
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  
  return `${month}/${day}/${year}`;
}

export function formatNumber(number) {
  return number.toString().replace(/\d(?=(\d{3})+$)/g, '$&,');
}

