import styled from '@emotion/styled';
import Users from './components/Users';
import Companies from './components/Companies';
import Accounts from './components/Accounts';
import Company from './components/Company';
import Account from './components/Account';
import SideNav from '../../components/SideNav';
import useActiveTab from '../../hooks/useActiveTab';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 10px;
`;

const ContentArea = styled.div`
  width: 100%;
`;

const SettingsPage = () => {
  const [activeTab] = useActiveTab();

  // Function to render the active tab's content
  const renderContent = () => {
    switch (activeTab) {
      case 'account':
        return <Account />;
      case 'users':
        return <Users />;
      case 'companies':
        return <Companies />;
      case 'company':
        return <Company />;
      case 'accounts':
        return <Accounts />;
      default:
        return <></>;
    }
  };

  return (
    <Container>
      <SideNav
        mainRoute='/settings/'
        routes={['account', 'company', 'users' /*'companies', 'accounts'*/]}
      />
      <ContentArea>{renderContent()}</ContentArea>
    </Container>
  );
};

export default SettingsPage;
