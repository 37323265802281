import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useAuth } from './AuthProvider';

// Define the GraphQL query to fetch the current user
const viewerQuery = gql`
  query ViewerQuery {
    viewer {
      id
      name
      email
      roles
      superAdmin
      admin
      company {
        type
        logoUrl
      }
    }
  }
`;

// Create a context
const CurrentUserContext = createContext({
  currentUser: null,
  currentCompany: null,
  loading: false,
  error: null,
});

// Create a provider component
export const CurrentUserProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const { data, loading, error } = useQuery(viewerQuery, {
    skip: !isAuthenticated,
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-only', // Used for subsequent executions
  });
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (data) {
      setCurrentUser(data.viewer);
    }
  }, [data]);

  const value = useMemo(() => {
    return {
      currentUser,
      currentCompany: currentUser?.company,
      loading,
      error,
    };
  }, [currentUser, error, loading]);

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export function useCurrentUser() {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider');
  }
  return context;
}

export default CurrentUserContext;
