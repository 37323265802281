import React, { createContext, useContext, useState } from 'react';

// Authentication context
const AuthContext = createContext({
  setAuth: (token: string) => {},
  setMaskedToken: (token: string) => {},
  logout: () => {},
  isAuthenticated: false,
  token: '',
});

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const maskedToken = localStorage.getItem('maskedToken');

  if (maskedToken) {
    sessionStorage.setItem('token', maskedToken);
    localStorage.removeItem('maskedToken');
  }
  const [authToken, setAuthToken] = useState(
    () => sessionStorage.getItem('token') || localStorage.getItem('token'),
  );

  const setMaskedToken = (token) => {
    localStorage.setItem('maskedToken', token);
  };

  const setAuth = (token) => {
    localStorage.setItem('token', token);
    sessionStorage.removeItem('token');
    setAuthToken(token); // Update state to re-render components
  };

  const logout = () => {
    if (sessionStorage.getItem('token')) {
      sessionStorage.removeItem('token');
    } else {
      localStorage.removeItem('token');
    }
    setAuthToken(null); // Update state to re-render components
  };

  return (
    <AuthContext.Provider
      value={{
        setAuth,
        setMaskedToken,
        logout,
        isAuthenticated: !!authToken,
        token: authToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use auth
export const useAuth = () => useContext(AuthContext);
