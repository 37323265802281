import { IoCloudUploadSharp } from 'react-icons/io5';
import DashIcon from './DashIcon';
import styled from '@emotion/styled';
import { useUploadManager } from '../contexts/UploadManagerContext';

const Wrapper = styled.div`
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 64px;
  height: 64px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #7bc9f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressCircle = styled.svg`
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: center;
`;

const UploadComponent = () => {
  const { showUploadManager, progress } = useUploadManager();
  const radius = 30; // Adjust radius accordingly
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;
  return (
    <Wrapper onClick={showUploadManager}>
      <ProgressCircle width='68' height='68'>
        <circle
          stroke='white'
          fill='transparent'
          strokeWidth='4'
          r='30'
          cx='34'
          cy='34'
        />
      </ProgressCircle>
      <ProgressCircle width='68' height='68'>
        <circle
          stroke={'#12699d'}
          fill='transparent'
          strokeWidth='4'
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          r='30'
          cx='34'
          cy='34'
        />
      </ProgressCircle>
      <IconContainer>
        <DashIcon icon={IoCloudUploadSharp} color='#fff' size={24} />
      </IconContainer>
    </Wrapper>
  );
};

export default UploadComponent;
