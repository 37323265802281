export function getMonthsBetween(startDate, endDate) {
  let start = new Date(startDate);
  let end = new Date(endDate);

  let monthsArray = [];

  while (start <= end) {
    // Add the formatted date to the array
    monthsArray.push(
      start.toLocaleString('en-us', { month: 'short' }) +
        ' ' +
        start.getFullYear().toString().substr(-2) +
        "'",
    );

    // Move to the next month
    start.setMonth(start.getMonth() + 1);
  }

  return monthsArray;
}