import React from 'react';
import { useModal } from '../contexts/ModalContext';

import styled from '@emotion/styled';
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; // Ensure it's above other content
`;

// Modal content styles
const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001; // Above the overlay
  width: 800px;
`;

/*const Modal = () => {
  const { modalContent, hideModal } = useModal();

  return modalContent
    ? createPortal(
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }} onClick={hideModal}>
          <div onClick={(e) => e.stopPropagation()}>{modalContent}</div>
        </div>,
        document.body
      )
    : null;
};*/

const Modal = ({ onOverlayClick, children }) => {
  const { closeModal } = useModal();
  return (
    <Overlay onClick={onOverlayClick || closeModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContent>
    </Overlay>
  );
};

export default Modal;
