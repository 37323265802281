import { useDropzone } from 'react-dropzone';
import DashUploadIcon from '../../../../components/DashUploadIcon';
import { useCallback } from 'react';
import { DirectUpload } from '@rails/activestorage';
import { useAuth } from '../../../../contexts/AuthProvider';
import updateCompanyMutation from './queries/updateCompany.graphql';
import { useMutation } from '@apollo/client';
import { useNotifications } from '../../../../contexts/NotificationsContext';
import { useCurrentUser } from '../../../../contexts/UserContext';
const Company = () => {
  const { token } = useAuth();
  const { showError, showSuccess } = useNotifications();
  const [updateCompany] = useMutation(updateCompanyMutation, {
    update: (cache, { data: { updateCompany } }) => {
      cache.modify({
        id: cache.identify(updateCompany.company),
        fields: {
          logoUrl() {
            return updateCompany.company.logoUrl;
          },
        },
        broadcast: false,
      });
    },
  });
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      const authenticationHeader = {
        Authorization: `Bearer ${token}`,
      };

      acceptedFiles.forEach((file) => {
        const upload = new DirectUpload(
          file,
          '/upload',
          undefined,
          authenticationHeader,
        );
        upload.create((error, blob) => {
          if (error) {
            console.error(error);
          } else {
            let signedId = blob.signed_id;
            //let url = `/rails/active_storage/blobs/${signed_id}/${"whatever_we_want_the_filename_to_be"}`;
            updateCompany({
              variables: {
                input: {
                  logoSignedId: signedId,
                },
              },
            })
              .then((response) => {
                // Handle success
                console.log('Logo saved:', response.data);
                showSuccess('Logo saved');
              })
              .catch((error) => {
                // Handle error
                console.error('Error saving user:', error);
                showError('Error saving user');
              });
          }
        });
      });
    },
    [showError, showSuccess, token, updateCompany],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/*': ['.jpg', '.png', '.jpeg'],
    },
    onFileDialogCancel: () => {},
    onDragEnter: () => {},
    onDragOver: () => {},
    onDragLeave: () => {},
  });
  const { currentCompany } = useCurrentUser();
  return (
    <div>
      <span>Logo: </span>
      <span>
        <img src={currentCompany?.logoUrl} alt='Company logo' width={200} />
      </span>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here ...</p> : null}
        {/* Button to explicitly open the file dialog */}
        <DashUploadIcon size={34} />
        Upload Documents
      </div>
    </div>
  );
};

export default Company;
