import { useState } from 'react';

import DashSelect from '../../../components/DashSelect';
import { useMockData } from '../../../contexts/MockDataContext';
import { startCase } from 'lodash';

import SearchBox from '../../../components/SearchBox';
import DashDatePicker from '../../../components/DashDatePicker';
import FilterWrapper from '../../../components/FilterWrapper';
import ExportClaims from './ExportClaims';

const ClaimsFilters = ({ setFilter }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const { getClaimStatuses } = useMockData();
  const [startDate, endDate] = dateRange;
  const statusOptions = getClaimStatuses().map((status) => ({
    value: status,
    label: startCase(status),
  }));
  return (
    <FilterWrapper style={{ justifyContent: 'left', marginTop: 40 }}>
      <DashDatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
          setFilter((filter) => ({
            ...filter,
            startDate: update[0],
            endDate: update[1],
          }));
        }}
      />
      <DashSelect
        options={statusOptions}
        placeholder='Select Statuses'
        onChange={(update: any[]) => {
          setFilter((filter) => ({
            ...filter,
            statuses: update.map((option) => option.value),
          }));
        }}
        isMulti
        isClearable
      />

      {/*<ExportClaims />*/}
      <SearchBox
        setSearch={(value) => {
          setFilter((filter) => ({ ...filter, search: value }));
        }}
      ></SearchBox>
    </FilterWrapper>
  );
};

export default ClaimsFilters;
