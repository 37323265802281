import styled from '@emotion/styled';

interface TrProps {
  headerRow?: boolean;
}

const Tr = styled.div<TrProps>`
  border-bottom: 1px solid #ddd;
  
  border-radius: 0px;
  margin-top: 0px;

  &:hover {
    background-color: #f4f4f4;
  }
  height: 40px;
  display: flex;
`;
export default Tr;