import React, { useState } from 'react';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import PDFCarousel from './PDFCarousel';
import styled from '@emotion/styled';

// Define the props type if necessary
type ClaimDetailsProps = {
  claim: any;
};

const ClaimDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3px;
`;

const LeftPane = styled.div`
  width: 45%;
  min-height: 550px;
  max-height: 550px;
`;

const RightPane = styled.div`
  width: 55%;
`;

const TabOptions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

const NavItem = styled.a`
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  color: #2f2d2d;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid #777;
  }
  ${(props: { active: boolean }) =>
    props.active
      ? `
    border-bottom: 2px solid #777;
  `
      : ''}
`;

const StyledDL = styled.dl`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 0;
  padding: 10px;
`;

// Styled <dt> component for the attribute name
const StyledDT = styled.dt`
  margin-right: 0.5rem; // Adjust based on your design
  flex-basis: 18%; // Adjust the width as necessary
  font-weight: bold;
  font-weight: 500;
  font-size: 16px;
  color: #2f2d2d;
`;

// Styled <dd> component for the attribute value
const StyledDD = styled.dd`
  margin-left: 0; // Remove default margin
  flex-basis: 71%; // Adjust the width as necessary
  margin: 0; // Remove default margin
  font-weight: 400;
  font-size: 16px;
  color: #6f6d6d;
`;

const StyledOl = styled.ol`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const StyledLI = styled.li`
  margin: 5px;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #6f6d6d;

  span:first-of-type {
    width: 200px;
    display: inline-block;
    font-weight: bold;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #2f2d2d;
  }
`;

const ClaimDetails: React.FC<ClaimDetailsProps> = ({ claim }) => {
  const [activeTab, setActiveTab] = useState<'details' | 'history'>('details');

  return (
    <ClaimDetailsWrapper>
      <LeftPane>
        <PDFCarousel pdfs={[claim.bol, claim.invoice]} />
      </LeftPane>
      <RightPane>
        {/* Tabs */}
        <TabOptions>
          <NavItem
            href=''
            onClick={(e) => {
              e.preventDefault();
              setActiveTab('details');
              return false;
            }}
            active={activeTab === 'details'}
          >
            Details
          </NavItem>
          <NavItem
            href=''
            onClick={(e) => {
              e.preventDefault();
              setActiveTab('history');
              return false;
            }}
            active={activeTab === 'history'}
          >
            History
          </NavItem>
        </TabOptions>
        {/* Tab Content */}
        <div>
          {activeTab === 'details' && (
            <StyledDL>
              <StyledDT>Ship From:</StyledDT>
              <StyledDD>2033 Olympic Blvd Walnut Creek, Ca 94595</StyledDD>
              <StyledDT>Ship To:</StyledDT>
              <StyledDD>2333 Roosevelt Ave, Berkeley Ca 94595</StyledDD>
              <StyledDT>Carrier:</StyledDT>
              <StyledDD>Logistic Towing</StyledDD>
              <StyledDT>Date:</StyledDT>
              <StyledDD>12/12/23</StyledDD>
              <StyledDT>Grand Total:</StyledDT>
              <StyledDD>$123</StyledDD>
              <StyledDT>BOL Number:</StyledDT>
              <StyledDD>01234560022320187</StyledDD>
              <StyledDT>ARN:</StyledDT>
              <StyledDD>25463012501</StyledDD>{' '}
              <StyledDT>Purchase Order:</StyledDT>
              <StyledDD>3MQWBE4A</StyledDD>
            </StyledDL>
          )}
          {activeTab === 'history' && (
            <StyledOl>
              <StyledLI>
                <span>Invoice Date</span>
                <span>8/8/23</span>
              </StyledLI>
              <StyledLI>
                <span>Shipped</span>
                <span>8/9/23</span>
              </StyledLI>
              <StyledLI>
                <span>Delivered</span>
                <span>8/10/23</span>
              </StyledLI>
              <StyledLI>
                <span>Shortage Claimed By Amazon</span>
                <span>10/11/23</span>
              </StyledLI>
              <StyledLI>
                <span>First Dispute</span>
                <span>10/11/23</span>
              </StyledLI>
              <StyledLI>
                <span>Amazon Rejected</span>
                <span>10/12/23</span>
              </StyledLI>
              <StyledLI>
                <span>Escalation - Exported</span>
                <span>10/24/23</span>
              </StyledLI>
              <StyledLI>
                <span>Reimbursed</span>
                <span>11/2/23</span>
              </StyledLI>
            </StyledOl>
          )}
        </div>
      </RightPane>
    </ClaimDetailsWrapper>
  );
};

export default ClaimDetails;
