import { useState } from 'react';
import PDFViewer from '../../../components/PdfViewer';
import DashIcon from '../../../components/DashIcon';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import styled from '@emotion/styled';

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
const SideBar = styled.div`
  position: absolute;
  height: 55px;
  width: 25px;
  border-radius: 5px;
  top: calc(50% - 28px);
  background-color: #f7f6f6;
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const SideBarLeft = styled(SideBar)`
  left: 4px;
`;

const SideBarRight = styled(SideBar)`
  right: 4px;
`;

const Documents = styled(PDFViewer)`
  display: flex;
  justify-content: center;
  width: calc(100% - 50px);
  margin-left: 25px;
`;

const PDFCarousel = ({ pdfs }) => {
  const [active, setActive] = useState(0);

  return (
    <CarouselWrapper>
      {pdfs.length > 1 && (
        <SideBarLeft
          onClick={() => {
            setActive((active - 1) % pdfs.length);
          }}
        >
          <DashIcon icon={FaChevronLeft} size={20}></DashIcon>
        </SideBarLeft>
      )}
      <Documents file={pdfs[active]} />
      {pdfs.length > 1 && (
        <SideBarRight
          onClick={() => {
            setActive((active + 1) % pdfs.length);
          }}
        >
          <DashIcon icon={FaChevronRight} size={20}></DashIcon>
        </SideBarRight>
      )}
    </CarouselWrapper>
  );
};

export default PDFCarousel;
