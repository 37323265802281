import Popup from 'reactjs-popup';
import DashIcon from '../../../components/DashIcon';
import { FaGear } from 'react-icons/fa6';
import styled from '@emotion/styled';
import 'reactjs-popup/dist/index.css';

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
const StyledLi = styled.li`
  text-decoration: none;
  list-style-type: none;
  :hover {
    background-color: #f0f0f0;
  }
  cursor: pointer;
  padding: 5px;
`;

const ActionMenu = () => {
  return (
    <Popup
      trigger={<DashIcon icon={FaGear} size={20} />}
      position='right center'
    >
      <StyledUl>
        <StyledLi
          onClick={() => {
            alert('CANCELED');
          }}
        >
          Cancel
        </StyledLi>
      </StyledUl>
    </Popup>
  );
};

export default ActionMenu;
