import { FaCalendar } from 'react-icons/fa';
import DashIcon from './DashIcon';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import colors from '../tokens/colors';
import sizes from '../tokens/sizes';
const StyledDatePicker = styled(DatePicker)`
  width: ${sizes.inputWidth + 50}px;
  cursor: pointer;
  padding-top: 10.75px !important;
  padding-bottom: 7px !important;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
`;
const DatePickerIcon = styled(DashIcon)`
  top: 4px;
  left: -2px;
`;
const DashDatePicker = (props) => (
  <StyledDatePicker
    selectsRange={true}
    showIcon={true}
    isClearable={true}
    placeholderText='Select Dates'
    icon={
      <DatePickerIcon icon={FaCalendar} color={colors.iconColor} size={24} />
    }
    {...props}
  />
);

export default DashDatePicker;
