import { createContext, useContext, useState } from 'react';

const ModalContext = createContext({
  showModal: (content: React.ReactNode) => {},
  closeModal: () => {},
  modalContent: null,
});

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);

  const showModal = (content) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <ModalContext.Provider value={{ showModal, closeModal, modalContent }}>
      {children}
      {modalContent}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
