const Company = () => {
  return (
    <div>
      <span>Name: </span>
      <span>Your name</span>
      <span>Email: </span>
      <span>Your email</span>
    </div>
  );
};

export default Company;
